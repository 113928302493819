<template>
	<div class="locked" v-if="!g.unlocked">
		<div class="lock"><i aria-hidden="true" class="fa fa-lock"></i></div>
		<div class="center-content">
			<input @keyup.enter="checkPassword()" v-model.trim="password" type="text" class="form-control password input light" placeholder="Password">
			<button class="btn btn-outline-dark" tabindex="0" @click="checkPassword">Unlock</button>
		</div>
		<div class="error" v-show="passwdError">Wrong Password!</div>
	</div>
</template>

<script>

	import * as md5 from 'md5';

	export default {
		name: 'unlock',
		data: function(){
			return {
				password: "",
				passwdError: "",
			}
		},
		created: function(){
			this.checkPassword();
			console.log(md5("test"));
		},

		methods: {
			checkPassword: function(){

				if (!this.g.config.passwordHash) this.g.unlocked = true;
				else{

					let cookie = getCookie('login');
					//let cookie = "";
					if (cookie == this.g.config.passwordHash){
						this.g.unlocked = true;
					}else{
						if (this.password){
							if (md5(this.password) == this.g.config.passwordHash){

								this.g.unlocked = true;
								setCookie("login",this.g.config.passwordHash,this.g.config.passwordCookieExpirationDays||90);
							}else{
								this.passwdError = true;
								this.password = "";
								this.g.unlocked = false;
							}
						}
					}
				}

			},
		},
	}

	function setCookie(cname, cvalue, exdays) {
		var d = new Date();
		d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
		var expires = "expires="+d.toUTCString();
		document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	}

	function getCookie(cname) {
		var name = cname + "=";
		var ca = document.cookie.split(';');
		for (var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) == ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length);
			}
		}
		return null;
	}

</script>

<style lang="less">

	.locked{
		margin-bottom: 2em;
		text-align: center;

		.center-content{
			position: relative;
			@media (min-width: 400px){
				flex-direction: row;
				justify-content: center;
			}
			@media (max-width: 399px){
				.btn{
					margin-top: 0.5em;
				}
			}
		}

		.lock{
			color: lightgray;
			font-size: 5em;
		}
		div.password{
			display: flex;
			justify-content: center;
			align-items: flex-start;
			flex-direction: row;
			flex-wrap: nowrap;
			> div{
				flex: 0 0 auto;
			}

		}

		.btn{
			flex: 0 0 auto;
			margin-top: 0;
			margin-left: 0.3em;
			margin-right: 0.3em;
			padding: 5px 15px;
		}

		.error{
			margin-top: 1em;
			color: gray;
			font-size: 1.4em;
		}
	}

</style>
