<template>
	<div class="container">
		<div class="tbl">
			<div class="rw header">
				<div class="cl" :style="{'width': firstcolwidth + 'em'}"><span>{{ program.title }}</span> <span v-if="!l.showLocalTimes">(CET)</span></div>
				<div
				class="cl"
				:style="{'width': colwidth}"
				>
				<span>
					Single Track
				</span>
				</div>
			</div>
			<div class="rw-container" v-for="(item) of program.items">
				<div class="rw">
					<div class="cl" :class="{break: item.break}" :style="{'width': firstcolwidth + 'em'}">
						{{time(convertTime(item.start),stripLeadingZeros(item.start))}}<span v-if="item.end">
								<br v-if="l.showLocalTimes"> - <br v-if="l.showLocalTimes">{{time(convertTime(item.end),stripLeadingZeros(item.end))}}
							</span>
						<button v-if="item.vimeo || item.youtube" class="btn btn-general" @click="openModal(item)"><i class="fa fa-film"></i> Rewatch</button>
						<button v-if="item.slides" class="btn btn-general" @click="getSlides(item)"><i class="fa fa-file-pdf"></i> Slides</button>
					</div>
					<div
					class="cl"
					:style="{'width': colwidth}"
					><div class="title" :class="{break: item.break}">{{ item.title }}</div>
					<div class="content">
						<div v-if="item.speaker">{{ item.speaker }} </div>
						<div v-if="item.affiliation" class="affiliation">{{ item.affiliation }} </div>
						<div v-if="item.ptype" class="ptype">({{item.ptype}} presentation)</div>
						<div v-if="item.chair" class="subtable">
							<div>Chair:</div>
							<div><i>{{ item.chair.name }}</i><span v-if="item.chair.affiliation">, {{ item.chair.affiliation }}</span></div>
						</div>
						<div  v-if="item.papers" class="subtable">
							<div>Papers:</div>
							<div>
								<div class="paper" v-for="paper of item.papers">
									<span class="authors">{{ paper.authors }}, </span>
									<span class="title">{{ paper.title }}</span>
									<span class="ptype" v-if="paper.ptype">({{ paper.ptype }} presentation)</span>
								</div>
							</div>
						</div>
						<div  v-if="item.misc" v-html="item.misc"/>
					</div>

				</div>
				</div>

			</div>
		</div>
	</div>
</template>


<script>


export default {
		name: "program",
		components: {
		},
		props: {
			firstcolwidth: {
				type: Number,
			},
			program: {
				type: Object,
				default: {}
			},
			day: {
				type: Number,
				default: 1
			}

		},
		data: function(){

			return {

			}
		},
		watch: {

		},
		methods: {
			convertTime(time){
				if (time.match(/^[0-9]:[0-9]{2}$/)){
					time = "0" + time
				}
				return this.program.date + "T" + time + ":00"

			},
			stripLeadingZeros(timeStr){
				if (!timeStr) return ""
				return timeStr.replace(/^0/,"")
			},
			openModal(item){
				item.time = this.convertTime(item.start)
				this.g.videomodal.openModal(item)
			},
			getSlides(item){
				item.time = this.convertTime(item.start)
				this.g.slidemodal.openModal(item)
			},

		},
		computed: {
			colwidth: function(){
				return "calc(100% - " + this.firstcolwidth + "em - 5px)"
			},
		},
		created: async function(){


		},

	}


</script>

<style lang="less" scoped>

	button {
		display: inline-block;
		margin: 0.5em;
		margin-top: 0.8em;

	}
	.container{
		background-color: #fcfcfc;
		z-index: 1;
	}
	.tbl{
		padding: 5px;
		overflow-x: auto;
		font-size: 1.3em;
		.rw {
			min-width: unset;
		}
	}
	.tbl .rw.header{
		font-weight: bold;
		border-bottom: 2px solid gray;
		background-color: rgb(193, 185, 201);
		border-top-left-radius: 0.15em;
		border-top-right-radius: 0.15em;
		margin-top: 0;
		padding: 0.2em 0;
		z-index: 0;
		.cl span {
			font-size: 1.1em;
			display: inline;
		}
	}

	.tbl > :not(:last-child){
		border-bottom: 1px solid rgb(193, 185, 201);
		min-width: 30em;
		padding-right: 1em;
		padding-bottom: 0.5em;
	}

	.tbl .cl {
		display: block;
	}
	.tbl .rw .cl{
		flex: 0 0 auto;
	}
	.tbl .rw .cl:nth-child(1):not(.break){
		font-weight: bold;
	}

	.tbl .rw .cl:nth-child(1){
		text-align: center;

	}
	.tbl .rw .cl:nth-child(2){
		text-align: left;

	}

	.tbl .rw .cl > div {
		margin-top: 0.1em;
	}
	.tbl .rw .cl > div.title {
		margin-top: 0;
	}

	.tbl .rw .cl > div.title + div.content:has(div){
		margin-bottom: 0.3em;
	}

	.tbl .rw-container{
		width: 100%;
		z-index: 0;
	}

	.subtable {
		display: flex; margin-top: 0.3em;
		> div:nth-child(1){
			flex: 0 0 5em;
		}
		> div:nth-child(2){
			flex: 1 1 auto;
		}
	}

	.subtable .paper:not(:first-child){
		margin-top: 0.5em;
	}

	.authors{
		font-style: italic;
	}
	.title{
		font-weight: bold;
	}
	.title.break {
		font-weight: normal;
	}
	.ptype{
		font-style: italic;
		display: block;
		margin-top: 0.2em;
	}
	.affiliation{
		font-style: italic;

	}

</style>
