<template>

	  <!-- Navigation -->
	<nav class="navbar navbar-default navbar-fixed-top">

		<div class="container">
			<!-- Brand and toggle get grouped for better mobile display -->
			<div class="navbar-header page-scroll">
				<button class="navbar-toggle" data-target="#bs-example-navbar-collapse-1" data-toggle="collapse" type="button">
					<i class="fa fa-bars" aria-hidden="true" style="color:lightgray;"></i><span class="sr-only">Toggle navigation</span>
				</button>
				<a class="navbar-brand raw" href="index.html">
					<img class="logo" src="../../../public/img/logo.svg" alt="IEEE 5G++ Summit">
				</a>
			</div>
			<!-- Collect the nav links, forms, and other content for toggling -->
			<div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
				<ul class="nav navbar-nav navbar-right">
					<li class="hidden"></li>
					<li v-for="item of visibleItems" class="page-scroll" :key="item.text">
						<a :href="'#' + getArgument(item.target)" v-html="getArgument(item.text)"></a>
					</li>
				</ul>
			</div>
			<!-- /.navbar-collapse -->
		</div>


	</nav>

</template>

<script>

	export default {
		name: 'mainNav',
		props: {
			menu: Array,
			getArgument: {
				type: Function,
				default: (a) => {
					if ( a === undefined ) return null;
					if ( typeof a == "object") return a.value
					return a
				}
			},
			dispNow: {
				type: Function,
				default: () => true
			}
		},
		computed: {
			visibleItems: function(){
				let res = [];
				for (let item of this.menu) if (this.dispNow(item)) res.push(item);
				return res;
			}
		}
	}

</script>

<style lang="less">

@import '../../style-variables.less';

img.logo{
	border: none;
	height: @logo-height;
	margin: @logo-margin;
}

nav a:active{
	color: unset;
}
nav a, nav a:focus{
	color: white !important;
}

.navbar-default .navbar-nav>li>a:hover, .navbar-default .navbar-nav>li>a:focus {
	color: @link-light !important;
}

@media(min-width:@layoutThreshold) {
    .navbar-fixed-top {
        padding: 20px 0;
        -webkit-transition: padding .3s;
        -moz-transition: padding .3s;
        transition: padding .3s;
    }

    .navbar-fixed-top .navbar-brand {
        font-size: 2em;
        -webkit-transition: all .3s;
        -moz-transition: all .3s;
        transition: all .3s;
    }

    .navbar-fixed-top.navbar-shrink {
        padding: 5px 0;
		ul {
			padding-top: 5px;
			li.page-scroll a{
				line-height: 10px !important;
			}
		}
	}


    .navbar-fixed-top.navbar-shrink .navbar-brand {
        font-size: 1.5em;
    }
}

.navbar-default {
	background-color: @navbar !important;
	box-shadow: 0px 3px 2px 0px rgba(0,0,0,0.2);
}

.navbar {
    /* text-transform: uppercase; */
    font-family: Montserrat,"Helvetica Neue",Helvetica,Arial,sans-serif;
    font-weight: 700;
}


.navbar-toggle{
	transition: all 0.2s ease-out;
}

.navbar-collapse{
	@media (max-width: 1199px) {
		border-top: 2px solid white !important;
	}
}





.navbar a:focus {
    outline: 0;
}


.navbar .navbar-nav {
    letter-spacing: 1px;
}

.navbar .navbar-nav li a:focus {
    outline: 0;
}

.navbar-default,
.navbar-inverse {
    border: 0;
}


</style>
