<template>

	<!-- Speaker Modal -->
	<div v-show="showmodal"  class="modal fade in">
		<div ref="modal-backdrop" class="modal-backdrop fade" @click="closeModal()"></div>
		<div class="modal-container">
			<div class="modal-dialog" role="dialog" ref="maindiv" @keyup.stop.prevent="handleKeyUp" tabindex="0">
				<div ref="modal-dialog" class="modal-content">

					<div class="scrollable">

						<div class="close-modal" @click="closeModal()">
							<div class="box">
								<div class="lr">
									<div class="rl">
									</div>
								</div>
							</div>
						</div>


						<div class="modal-body">
							<h2>
								{{title}}<br>
								<small>{{subtitle}}</small>
							</h2>
							<div><slot></slot></div>
						</div>


						<div class="modal-footer">
							<button v-if="type=='default'" type="button" class="btn btn-primary" @click="closeModal()" tabindex="0">Close</button>
							<button v-if="type=='save'" type="button" class="btn btn-primary no" @click="closeModal()" tabindex="0">Close</button>
							<button v-if="type=='save'" type="button" class="btn btn-primary yes" @click="save()" tabindex="0">Save</button>
							<button v-if="type=='yesno'" type="button" class="btn btn-primary yes" @click="yes()" tabindex="0">Yes</button>
							<button v-if="type=='yesno'" type="button" class="btn btn-primary no" @click="no()" tabindex="0">No</button>
						</div>
					</div>

				</div>
			</div>
		</div>
	</div>


</template>

<script>

	export default {
		name: 'generalModal',
		props: {
			type: {
				type: String,
				default: "default",
			},
			title: {
				type: String,
				default: "",
			},
			subtitle: {
				type: String,
				default: ""
			}
		},
		data: function(){
			return {
				showmodal: false,

			};
		},
		created: function(){

		},
		computed: {

		},
		methods: {
			openModal: function () {

				this.showmodal = true;
				document.body.classList.add("modal-open");
				let self = this;
				this.$nextTick(()=>{
					setTimeout(()=>self.$refs["modal-backdrop"].classList.add("in"),50);
					this.$refs.maindiv.focus();
				});

			},
			yes: function(){
				this.closeModal();
				this.$emit("yes");
			},
			no: function(){
				this.closeModal();
				this.$emit("no");
			},
			save: function(){
				this.closeModal();
				this.$emit("save");
			},
			handleKeyUp: function(e){
				if (e.key == "Escape") {
					this.closeModal();
				}else if (e.key == "Enter"){
					if (this.type=="yesno") this.yes();
					else if (this.type == "save") this.save();
				}
				return false;

			},
			closeModal: function () {
				this.showmodal = false;
				// this.$refs["speaker-modal"].style.display = "none";
				this.$refs["modal-backdrop"].classList.remove("in");
				document.body.classList.remove("modal-open");

				this.$emit("close");
			},
		},
	}

</script>

<style lang="less">

@import '../../style-variables.less';

.modal{
	display: block;
}

.close-modal {
	position: absolute;
	top: 25px;

	@media (max-width: 599px) {
		right: 30px;
	}
	@media (min-width: 600px) {
		right: 30px;
	}

	width: 35px;
	height: 35px;
	background-color: #ffffffbb;

	border-radius: 100%;
	cursor: pointer;
	z-index: 200;
	transition: all 200ms ease-out;

	.box{
		position: relative;
		left: 17px;
		top: 2px;
	}

	.lr,.rl{
		z-index: 1051;
		width: 2px;
		height: 30px;
		background-color: #2c3e50;
	}
	.lr {
		//margin-left: 50px;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);

	}
	.rl {
		-webkit-transform: rotate(90deg);
		-ms-transform: rotate(90deg);
		transform: rotate(90deg);
	}
}

.close-modal:hover {
    .box{
		opacity: .3;
	}
}


.modal-backdrop{
	height: 100vh;
	width: 100vw;

	top: 0px !important;
	left: 0px !important;

	transition: 200ms all linear !important;
}

.modal-backdrop.fade.in{
	opacity: 50% !important;
}



.modal-container{
	height: 100vh;
	width: 100vw;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
}


.scrollable{
	max-height: 100vh;
	overflow-y: auto;
}

.modal-dialog:focus{
	outline: none;
}

.modal-dialog{

	margin: none !important;
	width: 70em;
	max-width: 100%;


	.modal-content{

		border-radius: 10px;
		overflow: hidden;
		margin: 7px;
		-webkit-box-shadow: 0 3px 5px rgba(0,0,0,0.5);
		box-shadow: 0 3px 5px rgba(0,0,0,0.5);
		padding: 10px;
		@media (max-width: 599px) {
			.modal-body{
				padding-left: 15px;
				padding-right: 15px;

			}
		}
		@media (min-width: 600px) {
			.modal-body{
				padding-left: 50px;
				padding-right: 50px;
			}
		}


		h2{
			text-align: left;
			color: #2c3e50;
			small{
				color: #aaa;
				font-variant: small-caps;
			}
		}

		h4{
			margin-top: 1em;
		}
		a{ color: @link-dark !important;}
		p {
			color: #555;
		}

		label{
			margin-top: 0.5em;
			font-weight: bold;
		}

		@media (max-width: 500px){
			p,ul {
				font-size: 16px;
				text-align: left;
				line-height: 1.6;
				hyphens: auto;
			}
		}
		@media (min-width: 501px){
			p,ul {
				font-size: 19px;
				line-height: 30px;
				text-align: left;
				hyphens: auto;
			}
		}


		.modal-footer{
			border-top: 2px solid #e5e5e5 !important;
			@media (max-width: 1199px) {
				margin-left: 22px !important;
				margin-right: 22px !important;
				padding-right: 10px !important;
			}
			@media (min-width: 1200px) {
				margin-left: 50px !important;
				margin-right: 50px !important;
				padding-right: 10px !important;

			}

			button{
				width: 6em;
			}
		}

	}
}

.btn.btn-primary.yes{
	margin-left: 1em;
}
.btn.btn-primary.no{
	background-color: darkred !important;
}


</style>
