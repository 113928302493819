<template>
	<div ref="chat" class="chat-container chat-hidden">
		<div class="heading long" @click="showChat=!showChat" role="button" title="hide/unhide chat">
			<i aria-hidden="true" class="bubble fa fa-comment"></i>
			<span class="text">Ask the Panel</span>
			<div class="indicator">
				<i v-if="showChat" aria-hidden="true" class="fa fa-caret-down"></i>
				<i v-if="!showChat" aria-hidden="true" class="fa fa-caret-right"></i>
			</div>
		</div>
		<div class="heading short" @click="showChat=!showChat">
			<i class="bubble fa fa-comment"></i>
			<div class="text"><div>Ask the Panel</div></div>
			<div class="indicator">
				<i v-if="showChat" class="fa fa-caret-right" aria-hidden="true"></i>
				<i v-if="!showChat" class="fa fa-caret-left" aria-hidden="true"></i>
			</div>
		</div>
		<div class="chat">
			<iframe :src="url" width="100%" height="100%" frameborder="0"></iframe>
		</div>
		<div class="goto-chat" title="scroll to chat" role="button" @click="scrollToChat()"><i aria-hidden="true" class="bubble fa fa-comment"></i></div>
	</div>
</template>

<script>
	export default {
		name: 'chat',
		props: {
			url: String,
		},
		data: function(){
			return {
				showChat: false,
			}
		},
		watch: {
			showChat: function(){
				if (this.showChat) this.$refs.chat.classList.remove('chat-hidden');
				else this.$refs.chat.classList.add('chat-hidden');
			}
		},
		methods: {
			scrollToChat: function(){
				let rect = this.$refs.chat.getBoundingClientRect();
				window.scrollTo({top: rect.y + window.scrollY - 100, behavior: "smooth"});
			}
		},
	}

</script>

<style lang="less">
	.chat-container{
		margin-top: 1em;
		// border: 2px solid #777;
		border: 2px solid white;
		box-shadow: 0 0px 3px 2px rgba(0, 0, 0, 0.3);

		position: relative;
		border-radius: 5px;
		cursor: pointer;
		z-index: 1029;
		font-size: 1.4em;
		background-color: #18bc9c;
		overflow: hidden;

		&:hover{
			// background-color: saturate(#f3fbf9,60%);
			//background-color: desaturate(darken(#2b628c,10%),0%);
		}

		.chat {
			background-color: white;
			max-height: 85vh;
			iframe{
				border-radius: 5px;
				box-shadow: 0 0px 2px 2px rgba(0, 0, 0, 0.05);
			}
		}

		.heading{
			position: relative;
			color: white;
			display: flex;
			flex-wrap: nowrap;
			align-items: center;
			transition: all 200ms ease-out;
		}
		.heading:hover{
			//color: darken(#2c3e50,60%);
			text-shadow: 0px 0px 3px white;
		}

		.indicator{
			flex: 0 0 auto;
			font-size: 1.4em;

			//color: gray;
		}
		.bubble,.text{
			display: block;
			margin: 5px;
		}
		.bubble{
			flex: 0 0 auto;
		}
		.text{
			flex: 10 0 auto;
		}

		.goto-chat{
			display: none;
			cursor: pointer;
			position: fixed;
			right: 0.3em;
			bottom: 0.6em;
			border-radius: 50%;
			background-color: #18bc9c;
			color: white;
    		padding: 0.2em;
			font-size: 1.3em;
			border: 2px solid white;
			box-shadow: 0 0px 5px 4px rgba(0, 0, 0, 0.3);
			transition: all 200ms ease-out;
			&:hover{
				text-shadow: 0px 0px 3px white;
				text-decoration: none;
			}
		}

		@media (max-width: 799px) {
			width: 100%;
			padding: 8px !important;
			max-height: 34em !important;
			transition: all 0.3s ease-out;
			.indicator{
				margin-right: 0.2em;
				width: 0.5em;
				text-align: right;
			}
			&.chat-hidden{
				max-height: 2.8em !important;
			}

			.goto-chat{
				display: block;
			}

			.heading{
				flex-direction: row;
			}
			.heading.short{ display: none;}
			.chat{
				height: 30em;
				margin-top: 0.3em;
				width: 100%;
				max-height: 90%;

			}

		}
		@media (min-width: 800px){
			position: fixed !important;
			right: -2px;
			top: 5em;
			display: flex;
			flex-direction: row;
			height: 50em;
			max-height: 75vh;
			padding: 5px;

			box-shadow: 0 0px 5px 4px rgba(0, 0, 0, 0.3);
			transition: all 0.3s ease-out;

			&.chat-hidden{
				transform: translate(20.3em,0);
			}

			.heading{
				flex-direction: column;
			}
			.chat{
				//margin-top: -7em;
				width: 20em;
			}
			.heading.long{ display: none;}
			.heading.short{
				left: -2px;
				height: 100%;
				width: 2em;

				.text{

					display: flex;
					align-items: center;
					div{
						width: 10em;
						transform: rotate(-90deg);
					}
					//position: relative;
					//transform: rotate(-90deg);
				}
			}
		}
	}
</style>
