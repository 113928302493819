
export function initSchedule(schedule,startTime,utcoffset){

	schedule.time = startTime;
	schedule.showLocalTimes = false;
	schedule.limitedCountry = false;


	schedule.isLater = function(entry,now){
		if (!now) now = this.time;
		return ( now < Date.parse(entry.time + utcoffset))
	},
	schedule.isNow = function(entry,now){
		//console.debug(entry);
		if (!entry.time) return false;

		let from = Date.parse(entry.time + utcoffset);
		let till = from + entry.minutes * 60 * 1000;
		if (!now) now = this.time;
		return (now >= from) && (now < till);
	};
	schedule.hasPassed = function(entry,now){
		if (!now) now = this.time;
		if (now > Date.parse(entry.time+utcoffset) + entry.minutes * 60 * 1000) return true;
		return false;
	};
	schedule.isActive = function(e){
		let now = self.time;
		if (!e.active) return false;
		else{
			for (let span of e.active){
				if (now >= Date.parse(span.from+utcoffset) && now < Date.parse(span.till+utcoffset) ) return true;
			}
		}
		return false;
	};
	schedule.getCurrent = function(now){
		if (!now) now = this.time;

		// Sort From oldest to newest
		let arr = [];
		for (let k in this) arr.push(this[k]);

		arr = arr.sort((a,b)=>{
			if (a.time < b.time) return -1;
			if (a.time > b.time) return 1;
			return 0;
		});

		for (let e of arr)	if (this.isNow(e,now)) return e;
		return null;
	};

	schedule.localDateTime = function(e){
		let d = new Date(e.time + utcoffset);
		return d.toLocaleString([],{month: "short", day: "numeric", hour: "numeric", minute: "numeric"});
	};

	schedule.getNext = function(){
		let curr = this.getCurrent();
		let arr=[];
		for (let k in this) arr.push(this[k]);

		arr = arr.filter((a)=>(Date.parse(a.time+utcoffset) > this.time));
		if (arr.length == 0) return null;

		arr = arr.sort((a,b)=>{
			if (a.time < b.time) return -1;
			if (a.time > b.time) return 1;
			return 0;
		});

		let i = 1;
		let res = arr[0];

		while (curr && curr.main == res.main){
			if (arr[i]) res = arr[i];
			else return null;
			i++;
		}

		// Next must be at least 120 Minutes away
		if (Date.parse(res.time+utcoffset) - this.time > 120 * 60 * 1000) return null;

		return res;



		// console.log(arr);
		// let curr = schedule.getCurrent();
		// let t = this.time;
		// if (curr && curr.minutes) t = Date.parse(curr.time+utcoffset) + (curr.minutes-1) * 60 * 1000 ;

		// for (let i = 0; i < 120; i++){
		// 	let tmp = this.getCurrent(t);
		// 	if ( tmp && (!curr || (curr && tmp.main != curr.main))) return tmp;
		// 	t+= 60*1000;
		// }
		// return null;
	};
	schedule.minutesUntil = function(entry){

		if (!entry) return "";
		let diff = Date.parse(entry.time + utcoffset) - this.time;
		diff = Math.round(diff/1000);

		if (diff < 60) return "in <1 min.";
		else return "in " + Math.ceil(diff / 60) + " min.";

	},
	schedule.getTypeHTML = function(entry, liveOnly){
		if (!entry) return null;
		let res = "";
		if (!liveOnly && entry.type == "video") res = '<i aria-hidden="true" class="fa fa-film"></i> Video';
		if (entry.type == "live") res = '<span class="live"></span>';
		if (entry.type == "live-interactive") res = '<span class="live-interactive"></span>';
		return res;
	},
	schedule.getAnnouncementHTML = function(entry){

		let res = "";
		if (entry){
			res += entry.main;
			if (entry.lang=="de") res += '<span class="language">German</span>';
			if (entry.lang=="de/en") res += '<span class="language">German; English subtitles</span>';

		}
		return res;
	};

	schedule.generateICS = function(key, title, filename){

		let entry = this[key];

		let template = `
			BEGIN:VCALENDAR
			X-LOTUS-CHARSET:UTF-8
			VERSION:2.0
			BEGIN:VEVENT
			CLASS:PUBLIC
			DTSTART:#START#
			DTEND:#END#
			LOCATION:5gsummit.org/dresden
			SUMMARY:#TITLE#
			DESCRIPTION:#DESCRIPTION#
			END:VEVENT
			END:VCALENDAR`


		function toICStimeString(d){
			console.debug(d);
			let res = d.getFullYear().toString();
			res += pad(d.getMonth()+1);
			res += pad(d.getDate());
			res += "T";
			res += pad(d.getUTCHours());
			res += pad(d.getUTCMinutes());
			res += "00Z";
			console.log(res);
			return res;

			function pad(val){
				if (val < 10) return "0" + val;
				return val;
			}
		}

		let start = toICStimeString(new Date(entry.time + utcoffset));
		let end = toICStimeString(new Date(Date.parse(entry.time + utcoffset) + entry.minutes*60*1000));
		let description = entry.main.replace(/<span class="title">(.+?)<\/span>/,"\"$1\"").replace(/<.+?>/g,"").replace(/\n/g,"\\n").replace(/&nbsp;/g," ").replace(/&amp;/g,"&");
		description = description.replace(/\t/g," ").replace(/\s\s+/g," ").replace(/\\n /g,"\\n");

		let res = template.replace(/\t/g,"");
		res = res.replace("#DESCRIPTION#",description).replace("#START#", start).replace("#END#",end).replace("#TITLE#",title)

		console.log(res);
		// Creates text File with name "filename" and content "text" and downloads it.


		try{
			var element = document.createElement('a');
			element.setAttribute('href', 'data:text/calendar;charset=utf-8,' + encodeURIComponent(res));
			element.setAttribute('download', filename);
			element.style.display = 'none';
			document.body.appendChild(element);
			element.click();
			document.body.removeChild(element);
			return true;
		}catch(e){
			return false;
		}
	}

	return schedule;
}
