<template>

	<div class="schedule-search center-content">
		<div class="form-group has-feedback has-search has-button">
			<span aria-hidden="true" class="fa fa-search form-control-feedback"></span>
			<input ref="input" v-model.trim="search" type="text" @keypress.enter="scrollToResult()" class="form-control input light" placeholder="Search">
			<button id="take-me-there" tabindex="0" v-show="takeMeThere" @click="scrollToResult()" class="btn btn-outline-dark search-action">
				<i aria-hidden="true" class="fa fa-arrow-circle-down"></i>Take Me There
			</button>
		</div>
	</div>

</template>

<script>
	export default {
		name: 'schedulesearch',
		props: {},
		data: function(){
			return {
				search: "",
				takeMeThere: false,
				results: [],
			};
		},
		watch: {
			search: function(){
				let entries = document.querySelectorAll('.s-entry');
				let count = 0;
				let searchbox = this.$refs.input;
				this.results = [];

				for (let entry of entries){
					if (!this.search || entry.innerText.toLowerCase().includes(this.search.toLowerCase())){
						entry.classList.remove("not-in-search");
						count++;
						this.results.push(entry);
					}else entry.classList.add("not-in-search");
				}

				if (this.search && !count) searchbox.classList.add("no-result");
				else searchbox.classList.remove("no-result");

				if (count==1) this.takeMeThere = true;
				else this.takeMeThere = false;

			}
		},
		methods: {
			scrollToResult: function(){
				let self = this;
				if (this.results.length == 1){
					let res = this.results[0];
					let rect = res.getBoundingClientRect();
					let sched = document.querySelector('.tbl.schedule');
					//highlightSearchResults();

					window.scrollTo({top: rect.top + window.scrollY - 250, behavior: "smooth"});
					sched.scrollTo({left: rect.left + sched.scrollLeft - 50, behavior: "smooth"});
					res.classList.add('shighlight');

					setTimeout(() => {
						res.classList.remove('shighlight');
						self.search = "";
					}, 1000);
				}
			}
		},

	}


</script>

<style lang="less">

.schedule-search{
	margin-bottom: -1.3em;
	.form-group{
		max-width: 100%;
		input {
			width: 20em;
			max-width: 100%;
		}
	}
}
</style>
