<template>
	<!-- Header + Buttons -->
	<header :class="{'dark': header.darkCover}">
		<div class="container">
			<div class="row">
				<div class="col-lg-12">
					<div class="intro-text">
						<span class="name pre-title" style="font-weight: normal;" v-if="getArgument(header.preTitle)" v-html="getArgument(header.preTitle)"></span>
						<span class="name" style="font-size: 3em;" v-if="getArgument(header.title)" v-html="getArgument(header.title)"></span>
						<!-- <hr class="star-light"> -->
						<faruler></faruler>
						<div class="skills">
							<span v-if="getArgument(header.dates)" v-html="getArgument(header.dates)"></span><br>
							<span class="message" v-if="getArgument(header.message)" v-html="getArgument(header.message)"></span><br>

								<!-- BUTTONS -->
							<div style="margin-top: 1em">
								<a v-for="btn of visibleButtons" style="margin-left: 4px;" :key="btn.key" tabindex="0" class="btn btn-outline" :target="getArgument(btn.target).includes('http')?'_blank':'_self'" :href="getArgument(btn.target)"><span v-if="getArgument(btn.icon)"><i :class="btn.icon"></i>&nbsp;</span>{{getArgument(btn.text)}}</a>
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>
	</header>

</template>

<script>

	import faruler from './fa-ruler.vue';

	export default {
		name: 'mainHeader',
		props: {
			header: Object,
			getArgument: {
				type: Function,
				default: (a) => {
					if ( a === undefined ) return null;
					if ( typeof a == "object") return a.value
					return a
				}
			},
			dispNow: {
				type: Function,
				default: () => true
			}
		},
		components: {
			faruler: faruler,
		},
		computed: {
			visibleButtons: function(){
				let res = [];
				for (let btn of this.header.buttons){
					if (this.dispNow(btn)) res.push(btn);
				}
				return res;
			}
		},
		methods: {

		}
	}

</script>

<style lang="less">
	@import '../../style-variables.less';

	header {
		text-align: center;
		// color: #fff;
		background: #2c3e50;
		// background: linear-gradient(      rgba(5, 5, 5, 0.35),       rgba(0, 0, 0, 0.45)    ), @cover no-repeat 50% 25%;
		background: @cover no-repeat 50% 25%;
		background-size: cover;
	}

	header .container {
		padding-top: 100px;
		padding-bottom: 50px;
	}

	header img {
		display: block;
		margin: 0 auto 20px;
	}

	header .intro-text .name {
		display: block;
		/* text-transform: uppercase; */
		font-family: Montserrat,"Helvetica Neue",Helvetica,Arial,sans-serif;
		font-size: 2.3em;
		font-weight: 700;
	}

	header .intro-text .skills {
		font-size: 1.25em;
		font-weight: 300;
	}

	header span.message{
		display: block;
		color: @link-light;
		font-weight: bold;
		margin-top: 1em;
		font-size: 1.3em;
	}

	header.dark {
		.btn, .ruler {
			color: #ddd !important;
		}

	}
	header .btn:hover{
		color: black !important;
	}

	@media(min-width:@layoutThreshold) {
		header .container {
			padding-top: 200px;
			padding-bottom: 100px;
		}

		header .intro-text .name {
			font-size: 3.75em;
		}

		header .intro-text .skills {
			font-size: 1.75em;
		}
	}
</style>
