
import {config} from '../config.js';
import {content} from '../content/content.mjs';
import {schedule} from '../content/schedule.mjs';
import {organizers} from '../content/organizers.mjs';
import {initSchedule} from './schedule-logic.js';
import {legal} from '../content/legal.mjs';
import {accessibility} from '../content/accessibility.mjs';
import { programdata } from '../content/program.mjs';

import { logicMixin, logicData } from '../logic.js';


const sharedData = {
	config: config,
	content: content,
	organizers: organizers,
	programdata: programdata,
	legal: legal,
	accessibility: accessibility,

	schedule: initSchedule(schedule, config.startTime,config.utcoffset),


	limitedCountry: false,
	unlocked: false,
	openModal: false,

	time: 0,
	time_offset: 0,
	showLocalTimes: false,

	videomodal: null,
	slidemodal: null,
}

// Setting time and cutting ms off time value
sharedData.time = Math.floor( ( config.startTime || Date.now() ) / 1000 ) * 1000;
sharedData.time_offset = Math.floor( Math.min( 0, config.startTime - Date.now() ) / 1000 ) * 1000;
if ( sharedData.time_offset < 5000 ) sharedData.time_offset = 0;

// Setting limitedCountry
( async function( ) {

	try{
		let url = window.location.toString().startsWith("https")?"https://ip-api.com/json":"http://ip-api.com/json"
		let response = await fetch( url )
		let json = await response.json()
		sharedData.limitedCountry = config.limitedCountryCodes.includes(json.countryCode);
		sharedData.schedule.limitedCountry = sharedData.limitedCountry;
		console.log("Country Code: " + json.countryCode);
	}catch(e){
		console.log("Request failed, error= " + err);
		console.log("Failed to get Country Code");
	}

})();

export default {
	data: function(){
		return {
			g: sharedData,
			l: logicData,
		}
	},
	mixins: [ logicMixin ],
	methods: {
		assignNewContent_$: function(data){
			this.$set(this.g,"content",data.content);
			this.$set(this.g,"schedule",initSchedule(data.schedule));
		}
	}
}
