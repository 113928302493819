<template>
	<div class="container">

		<div class="tbl">
			<div class="rw header">
				<div class="cl" :style="{'width': firstcolwidth + 'em'}"></div>
				<div v-for="category of pricecategories"
				class="cl"
				:style="{'width': colwidth}" v-html="category"
				/>
			</div>
			<div class="rw-container" v-for="(regtype,regindex) of registrationtypes">
				<div class="rw">
					<div class="cl" :style="{'width': firstcolwidth + 'em'}">{{ regtype }}</div>
					<div
					v-for="(category,catindex) of pricecategories"
					class="cl"
					:style="{'width': colwidth}"
					>{{ getPrice(pricedata[regindex][catindex]) }}</div>
				</div>
				<div v-if="benefitsdata.length > 0" class="benefits-container">
					<div class="benefits">
						<button @click="showHideBenefits(regindex)">
							<span v-if="!showBenefits[regindex]">Show</span><span v-else>Hide</span> Benefits <span class="fa fa-caret-right" v-if="!showBenefits[regindex]"/><span class="fa fa-caret-down" v-else/>
						</button>
						<div v-show="showBenefits[regindex]">
							<table>
								<tr>
									<th v-for="(b,bindex) of benefitsdata[regindex]"
									v-html="benefits[bindex]"
									></th>
								</tr>
								<tr>
									<td v-for="(b,bindex) of benefitsdata[regindex]">
										<span v-if="b" class="fa fa-check"/><span v-else>-</span>
									</td>
								</tr>
							</table>
						</div>
					</div>
			</div>
		</div>
	</div>
</div>
</template>

<script>

	import { config } from '../config.js';

	const testdata = {

	}


	export default {
		name: "priceTable",
		props: {
			registrationtypes: {
				type: Array,
			},
			firstcolwidth: {
				type: Number,
			},
			pricecategories: {
				type: Array,
			},
			benefits: {
				type: Array,
			},
			pricedata: {
				type: Array,
			},
			benefitsdata: {
				type: Array,
				default: ()=>[]
			},
			vat: {
				type: Number,
			},

		},
		data: function(){

			return {
				showBenefits : []
			}
		},
		watch: {

		},
		methods: {
			getPrice: function(price){
				if ( price == 0 ) return "Free"
				if ( price == '-' ) return "-"
				if (this.vat > 0){
					let p = Math.round( price * ( 1 + this.vat/100 ) * 100 ) / 100
					p = p + ""
					if ( p.match( /^\d+$/) ) p += ".00"
					if ( p.match( /^\d+\.\d$/) ) p += "0"
					price = p
				}
				return price + " €"
			},
			showHideBenefits: function(index){
				this.$set(this.showBenefits,index,!this.showBenefits[index])
			}
		},
		computed: {
			colwidth: function(){
				return "calc(" + (100 / this.pricecategories.length) + "% - " + this.firstcolwidth / this.pricecategories.length + "em - 5px)"
			},
		},
		created: async function(){

			for ( let i = 0; i < this.benefitsdata.length; i++ ){
				this.showBenefits.push( false )
			}
		},

	}

</script>

<style lang="less" scoped>
	.container{
		background-color: #fcfcfc;
		z-index: 1;
	}
	.tbl{
		padding: 5px;
		overflow-x: auto;
		// z-index: 2;
	}
	.tbl .rw.header{
		font-weight: bold;
		border-bottom: 2px solid gray;
		background-color: rgb(193, 185, 201);
		border-top-left-radius: 0.15em;
		border-top-right-radius: 0.15em;
		margin-top: 0;
		padding: 0.2em 0;
		z-index: 0;
	}
	.tbl .rw .cl{
		flex: 0 0 auto;
	}
	.tbl .rw .cl:nth-child(1){
		font-weight: bold;
	}

	.tbl .rw-container{
		width: 100%;
		z-index: 0;
	}

	.benefits-container{
		width: 100%;
		border-bottom: 2px solid #ddd;
		min-width: 75rem;
		margin-bottom: 0.2em;

	}

	.tbl .rw-container:last-of-type .benefits-container{
		border-bottom: none;
	}
	.benefits {
		font-size: 0.8em;
		color: #777;


		background-color: #eee;
		transition: all 0.2s;
		padding: 0.5em;
		margin: 0.5em 3em;
		//margin-bottom: 0.2em;
		border-top-left-radius: 0.5em;
		border-top-right-radius: 0.5em;
		//box-shadow: 2px 2px 6px rgba(0,0,0,0.1);


		button{
			transition: all 0.2s;
			border: none;
			background-color: transparent;
			cursor: pointer;
			border-radius: 0.5em;
			outline: 1px solid transparent;
			width: 10em;
			padding: 0.1em 0.3em;
			text-align: center;
			z-index: 30;
			position: relative;
			&:hover{
				outline: 1px solid gray
			}
		}


		& > div{
			width: 100%;
			table{
				text-align: center;
				width: 100%;
				th{
					border-bottom: 1px solid gray;
					//background-color: rgb(193, 185, 201);
					padding: 0.5em;
					font-weight: normal;
					text-align: center;
				}
				td{
					padding: 0.5em;
					text-align: center;
				}
			}
		}
	}

</style>
