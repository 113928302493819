<template>
	<span v-html="text"></span>
</template>

<script>

	import { config } from '../config.js';

	export default {
		props: {
			to: {
				type: String,
			},
		},
		data: function(){
			return {

				till: null,
				now: null,
				handle: null,
			}
		},
		watch: {
			to: function(){
				this.till = Date.parse( this.to )
			}
		},
		computed: {
			text: function(){
				if ( ! this.till || ! this.now ) return ""
				let diff = this.till - this.now
				let days = Math.floor( diff / ( 24 * 60 * 60 * 1000 ) )
				diff -= days * 24 * 60 * 60 * 1000
				let hours = Math.floor( diff / ( 60 * 60 * 1000 ) )
				diff -= hours * 60 * 60 * 1000
				let minutes = Math.floor( diff / ( 60 * 1000 ) )
				diff -= minutes * 60 * 1000
				let seconds = Math.floor( diff / 1000 )

				let res = []

				let set = false

				if ( days > 0 ) {
					if ( days == 1 ) res.push("1&nbsp;day")
					if ( days != 1 ) res.push(days + "&nbsp;days ")
					set = true
				}
				if ( hours > 0 || set ){
					if ( hours == 1 ) res.push("1&nbsp;hour")
					if ( hours != 1 ) res.push(hours + "&nbsp;hours")
					set = true
				}
				if ( minutes > 0 || set ){
					if ( minutes == 1 ) res.push("1&nbsp;minute")
					if ( minutes != 1 ) res.push(minutes + "&nbsp;minutes")
					set = true
				}
				if ( seconds > 0 || set ){
					if ( seconds == 1 ) res.push("1&nbsp;second")
					if ( seconds != 1 ) res.push(seconds + "&nbsp;seconds")
					set = true
				}


				return res.join(", ")


			}
		},
		created: async function(){
			this.till = Date.parse( this.to + config.utcoffset )
			this.now = Date.now()

			let self = this;

			await wait( 1000 - ( Date.now() % 1000 ) )
			this.now = Date.now()

			this.handle = setInterval( () => {
				self.now = Date.now()
				if ( self.now > self.till ){
					self.handle = null
					clearInterval( self.handle )
				}
			}, 1000 );

			function wait( ms ){
				return new Promise( ( resolve, reject ) => {
					setTimeout( () => {
						resolve()
					}, ms);
				})
			}

		},

	}

</script>

<style lang="less">

</style>
