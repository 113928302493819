
export var schedule = {
	"Tue 10:50-1": {
		time: "2020-09-29T10:50:00",
		minutes: 3,
		type: "video",
		ieeeTVid: "173211",
		vimeo: "462362086",
		logo: "cospon_5Gpp_Lab.png",
		main: `<span class="title">Opening Remarks</span>
		<span class="name">Prof. Frank Fitzek and Prof. Gerhard Fettweis</span>
		<span class="aff">5G&nbsp;Lab&nbsp;Germany</span>`,
	},
	"Tue 11:00-1": {
		time: "2020-09-29T11:00:00",
		minutes: 3,
		type: "video",
		ieeeTVid: "173081",
		vimeo: "461722443",
		logo: "processed/city-of-dresden.jpg",
		main: `<span class="title">Robot Valley Dresden – Europe's Top Location for Research and Business</span>
		<span class="aff">City of Dresden</span>`,
	},
	"Tue 11:00-2": {
		time: "2020-09-29T11:10:00",
		minutes: 4,
		type: "video",
		ieeeTVid: "173051",
		vimeo: "461712726",
		teaser: "461712840",
		logo: "processed/tu-dresden.jpg",
		main: `<span class="title">Radar Based Silent Speech Recognition</span>
		<span class="name">Christoph Wagner</span>
		<span class="aff">TU&nbsp;Dresden //&nbsp;Chair of Speech Technology and Cognitive Systems</span>`,
	},
	"Tue 11:20-1": {
		time: "2020-09-29T11:20:00",
		minutes: 3,
		type: "video",
		ieeeTVid: "173256",
		vimeo: "462593685",
		logo: "processed/barkhausen-institut.jpg",
		main: `<span class="title">Low-Latency Robotic Airhockey</span>
		<span class="aff">Barkhausen Institut</span>`,
	},
	"Tue 11:20-2": {
		time: "2020-09-29T11:25:00",
		minutes: 3,
		type: "video",
		vimeo: "461801760",
		ieeeTVid: "172966",
		logo: "processed/smart-systems-hub.jpg",
		lang: "de/en",
		main: `<span class="title">Innovations Become Products</span>
		<span class="name">Michael Kaiser</span>
		<span class="aff">Smart Systems Hub</span>`,
	},
	"Tue 11:20-3": {
		time: "2020-09-29T11:30:00",
		minutes: 7,
		type: "video",
		vimeo: "462364438",
		teaser: "461699060",
		ieeeTVid: "173216",
		lang: "de",
		main: `<span class="title">5G Solutions for the Connected Mobility of the Future</span>
		<span class="name">Dr. Frank Hofmann</span>
		<span class="aff">Bosch</span>`,
		logo: "processed/bosch.png",
	},
	"Tue 11:40-1": {
		time: "2020-09-29T11:40:00",
		minutes: 13,
		type: "video",
		teaser: "461706209",
		ieeeTVid: "173016",
		vimeo: "461705981",
		logo: "processed/tu-dresden.jpg",
		main: `<span class="title">Synthesizing Plausible Tactile Virtual Environments</span>
				<span class="name">Robert Rosenkranz</span>
				<span class="aff">TU&nbsp;Dresden //&nbsp;Chair&nbsp;of Acoustic & Haptic Engineering and CeTI</span>`,
	},
	"Tue 12:00-1": {
		time: "2020-09-29T12:00:00",
		minutes: 16,
		type: "video",
		ieeeTVid: "173026",
		vimeo: "461710110",
		teaser: "461710337",
		logo: "processed/tu-dresden.jpg",
		main: `<span class="title">MOBATSim: Virtual Validation of Autonomous Vehicle Functions</span>
				<span class="name">Mustafa Saraoğlu</span>
				<span class="aff">TU&nbsp;Dresden //&nbsp;Chair&nbsp;of Automation Engineering</span>`,
	},
	"Tue 12:20-1": {
		time: "2020-09-29T12:20:00",
		minutes: 20,
		main: `<span class="break">Lunch Break</span>`,
	},
	"Tue 12:40-1": {
		time: "2020-09-29T12:40:00",
		minutes: 20,
		main: `<span class="break">Lunch Break</span>`,
	},
	"Tue 13:00-1": {
		time: "2020-09-29T13:00:00",
		minutes: 3,
		type: "video",
		vimeo: "461801918",
		ieeeTVid: "172991",
		main: `<span class="title">Project Overview – Joint Research Project BAUEN 4.0</span>
		<span class="name">Prof. Jürgen Weber, Prof. Frank Will, Dr. Norman Franchi, Stephan Kessler and Dr. Marco Fecke</span>
		<span class="aff">Bauen&nbsp;4.0</span>`,
	},
	"Tue 13:00-2": {
		time: "2020-09-29T13:04:00",
		minutes: 10,
		type: "video",
		ieeeTVid: "173011",
		vimeo: "461716731",
		teaser: "461716874",
		logo: "processed/tu-dresden.jpg",
		main: `<span class="title">Connected Off-Highway Machines and Services – Large Scale Lab-Demonstrator Activities</span>
		<span class="name">Benjamin Beck and Richard Jacob</span>
		<span class="aff">TU&nbsp;Dresden //&nbsp;Bauen&nbsp;4.0</span>`,
	},
	"Tue 13:00-3": {
		time: "2020-09-29T13:15:00",
		minutes: 3,
		type: "video",
		ieeeTVid: "173041",
		vimeo: "462366910",
		lang: "de/en",
		logo: "processed/tu-dresden.jpg",
		main: `<span class="title">Accelerating Innovation by Experiment and Collaboration – Vision of Future Construction Field</span>
		<span class="name">Prof. Jürgen Weber</span>
		<span class="aff">TU&nbsp;Dresden //&nbsp;Chair&nbsp;of <br> Fluid-Mechatronic Systems</span>`,
	},
	"Tue 13:20-1": {
		time: "2020-09-29T13:20:00",
		minutes: 18,
		type: "video",
		ieeeTVid: "173261",
		vimeo: "461722576",
		lang: "de",
		main: `<span class="title">fast robotics</span>
		<span class="aff">fast robotics</span>`,
	},
	"Tue 13:40-1": {
		time: "2020-09-29T13:40:00",
		minutes: 5,
		type: "video",
		ieeeTVid: "173021",
		vimeo: "461804360",
		teaser: "461706310",
		logo: "processed/tu-dresden.jpg",
		main: `<span class="title">Embedded Systems Demonstrators</span>
		<span class="name">Prof. Diana Göhriner, Sergio Pertuz, Cornelia Wulf and Pedram Amini Rad</span>
		<span class="aff">TU&nbsp;Dresden //&nbsp;Chair&nbsp;of Adaptive Dynamic Systems</span>`,
	},
	"Tue 13:40-2": {
		time: "2020-09-29T13:47:00",
		minutes: 10,
		type: "video",
		ieeeTVid: "173236",
		vimeo: "461802125",
		teaser: "461802469",
		logo: "processed/tu-dresden.jpg",
		main: `<span class="title">Will Mobile Machines Become Robotic Systems?</span>
		<span class="name">Prof. Thomas Herlitzius</span>
		<span class="aff">TU&nbsp;Dresden //&nbsp;Chair&nbsp;of Agricultural Systems and Technology</span>`,
	},
	"Tue 14:00-1": {
		time: "2020-09-29T14:00:00",
		minutes: 2,
		type: "video",
		ieeeTVid: "173251",
		vimeo: "462586498",
		main: `<span class="title">Human-Machine Cooperation in Robot-Assisted Surgery</span>
		<span class="aff">TU Dresden // National Center for Tumor Disease and CeTI</span>`,
	},
	"Tue 14:20-1": {
		time: "2020-09-29T14:20:00",
		minutes: 20,
		ieeeTVid: "",
		main: `<span><span class="break">Coffee Break</span>`,
	},
	"Tue 14:40-1": {
		time: "2020-09-29T14:40:00",
		minutes: 20,
		ieeeTVid: "",
		main: `<span><span class="break">Coffee Break</span>`,
	},
	"Tue 15:00-1": {
		time: "2020-09-29T15:00:00",
		minutes: 60,
		ieeeTVid: "",
		vimeo: "463182533",
		selfHostedFilename: "PanelDiscussion_5GEnabledDigitalHealthTracingAppsasaFirstStep.mp4",
		type: "video",
		main: `<span class="title">5G++ Enabled Digital Health - Tracing Apps as a First Step</span>
				Panel Discussion with
				<strong>Prof. Gerhard Fettweis</strong> (5G&nbsp;Lab),
				<strong>Prof. Alexander Kekulé</strong> (Martin Luther University Halle-Wittenberg),
				<strong>Chris Boos</strong> (Arago) and
				<strong>Dr. Stefan Köpsell</strong> (Barkhausen Institut)
				`,
	},
	"Wed 11:00-1": {
		time: "2020-09-30T11:00:00",
		minutes: 16,
		type: "video",
		ieeeTVid: "173076",
		teaser: "461715149",
		vimeo: "461714950",
		logo: "processed/xilinx.jpg",
		main: `<span class="title">Strategic 5G Market Developments & Xilinx Open RAN Solutions</span>
		<span class="name">Brendan Farley</span>
		<span class="aff">Xilinx</span>`,
	},
	"Wed 11:20-1": {
		time: "2020-09-30T11:20:00",
		minutes: 3,
		type: "live-interactive",
		active: [
			{from: "2020-09-30T11:20:00", till: "2020-09-30T14:49:00"},
			{from: "2020-10-01T11:20:00", till: "2020-10-01T14:49:00"},
					],
		link: "https://agv.demo.vodafone-chair.org/Qui6iud6Sh/",
		teaser: "462925807",
		teaserSelfHosted: "ExperiencetheWirelessChannelfromaCloud-ControlledAGVPerspective.mp4",
		logo: "processed/tu-dresden.jpg",
		main: `<span class="title">Experience the Wireless Channel from a Cloud-Controlled AGV's Perspective</span>
		<div class="big">ONLINE DEMO</div>
		<span class="aff">TU&nbsp;Dresden //&nbsp;Vodafone Chair Mobile Communications Systems</span>`,
	},
	"Wed 11:20-2": {
		time: "2020-09-30T11:26:00",
		minutes: 5,
		type: "video",
		ieeeTVid: "173066",
		teaser: "461714862",
		vimeo: "461714765",
		logo: "processed/tu-dresden.jpg",
		main: `<span class="title">The Online Wireless Lab (OWL) Testbed</span>
		<span class="name">Zhitao Lin</span>
		<span class="aff">TU Dresden // Vodafone Chair Mobile Communications Systems</span>`,
	},
	"Wed 11:20-3": {
		time: "2020-09-30T11:33:00",
		minutes: 5,
		type: "video",
		ieeeTVid: "173046",
		vimeo: "462411896",
		logo: "processed/tu-dresden.jpg",
		main: `<span class="title">Integrated Photonics for Next Generation Devices</span>
		<span class="name">Dr. Mohammad Taghi Fathi, Shahryar Sabouri, Mircea Catuneanu</span>
		<span class="aff">TU&nbsp;Dresden //&nbsp;Chair of Radio Frequency and Photonics Engineering</span>`,
	},
	"Wed 11:40-1": {
		time: "2020-09-30T11:40:00",
		minutes: 14,
		type: "video",
		ieeeTVid: "172956",
		vimeo: "461704376",
		teaser: "461704621",
		logo: "processed/rohde-&-schwarz.png",
		main: `<span class="title">5G Evolution on the Verge to 6G – a&nbsp;Rohde&nbsp;&&nbsp;Schwarz Virtual Demo Tour</span>
		<span class="name">Meik Kottkamp</span>
		<span class="aff">Rohde&nbsp;&&nbsp;Schwarz</span>`,
	},
	"Wed 12:00-1": {
		time: "2020-09-30T12:00:00",
		minutes: 15,
		type: "video",
		ieeeTVid: "",
		selfHostedFilename: "NewNetworkArchitecturefor6G.mp4",
		vimeo: "462922807",
		logo: "processed/huawei1.png",
		main: `<span class="title">New Network Architecture for&nbsp;6G</span>
			<span class="name">Dr. Wen Tong</span>
			<span class="aff">Huawei</span>`,
	},
	"Wed 12:20-1": {
		time: "2020-09-30T12:20:00",
		minutes: 20,
		main: `<span class="break">Lunch Break</span>`,
	},
	"Wed 12:40-1": {
		time: "2020-09-30T12:40:00",
		minutes: 20,
		main: `<span class="break">Lunch Break</span>`,
	},
	"Wed 13:00-1": {
		time: "2020-09-30T13:00:00",
		minutes: 7,
		type: "video",
		ieeeTVid: "173091",
		teaser: "461702285",
		vimeo: "461701950",
		logo: "processed/globalfoundries.png",
		main: `<span class="title">Aspects&nbsp;&amp; Challenges of Chip Package Co-Design</span>
		<span class="name">Saquib Bin Halim</span>
		<span class="aff">GlobalFoundries</span>`,
	},
	"Wed 13:00-2": {
		time: "2020-09-30T13:07:00",
		minutes: 6,
		type: "video",
		ieeeTVid: "",
		selfHostedFilename: "AnEnhancedRFmmWSwitchusingSOIMOSFET.mp4",
		vimeo: "462942049",
		logo: "processed/globalfoundries.png",
		main: `<span class="title">An Enhanced RF/mmW Switch Using SOI MOSFET</span>
		<span class="name">Nan Wu</span>
		<span class="aff">GlobalFoundries</span>`,

	},
	"Wed 13:00-3": {
		time: "2020-09-30T13:13:30",
		minutes: 6,
		type: "video",
		ieeeTVid: "173086",
		vimeo: "461701655",
		main: `<span class="title">Packaging-IC Co-Design for Improved IC Performance</span>
		<span class="name">Christian Tschoban</span>
		<span class="aff">Fraunhofer IZM</span>`,
	},
	"Wed 13:20-1": {
		time: "2020-09-30T13:20:00",
		minutes: 16,
		type: "video",
		ieeeTVid: "173036",
		teaser: "461701251",
		vimeo: "461700455",
		logo: "processed/tu-dresden.jpg",
		main: `<span class="title">Finite Element Analysis of the Board Level Reliability of High Frequency Automotive Package Developments</span>
		<span class="name">Dr. Karsten Meier</span>
		<span class="aff">TU&nbsp;Dresden //&nbsp;Chair&nbsp;of Electronic Packaging Technology</span>`,
	},
	"Wed 13:40-1": {
		time: "2020-09-30T13:40:00",
		minutes: 8,
		type: "video",
		ieeeTVid: "173031",
		vimeo: "461699613",
		logo: "processed/tu-dresden.jpg",
		main: `<span class="title">22FDX Circuits for Integrated Dual-Band 5G Transceivers</span>
		<span class="name">Dr. Corrado Carta</span>
		<span class="aff">TU&nbsp;Dresden //&nbsp;Chair&nbsp;of Circuit Design and Network Theory</span>`,
	},
	"Wed 13:40-2": {
		time: "2020-09-30T13:53:00",
		minutes: 2,
		type: "video",
		ieeeTVid: "",
		selfHostedFilename: "ORCA26GHzmmWave.mp4",
		vimeo: "462923864",
		logo: "processed/tu-dresden.jpg",
		main: `<span class="title">ORCA 26GHz mmWave</span>
		<span class="aff">TU Dresden // Vodafone Chair Mobile Communications Systems</span>`,
	},
	"Wed 14:00-1": {
		time: "2020-09-30T14:00:00",
		minutes: 19,
		type: "video",
		vimeo: "461703198",
		teaser: "461693571",
		ieeeTVid: "173206",
		logo: "processed/infineon.jpg",
		main: `<span class="title">RF Semiconductors for 5G Networks and Handhelds</span>
		<span class="name">Dr. Ludger Verweyen</span>
		<span class="aff">Infineon</span>`,
	},
	"Wed 14:20-1": {
		time: "2020-09-30T14:20:00",
		minutes: 21,
		type: "video",
		ieeeTVid: "172946",
		teaser: "461703278",
		vimeo: "461703250",
		logo: "processed/ni.png",
		main: `<span class="title">Addressing the Challenges of mmW Over-the-Air (OTA) Validation</span>
		<span class="name">Alejandro Buritica</span>
		<span class="aff">NI</span>`,
	},
	"Wed 14:40-1": {
		time: "2020-09-30T14:41:00",
		minutes: 20,
		main: `<span><span class="break">Coffee Break</span>`,
	},
	"Wed 15:00-1": {
		time: "2020-09-30T15:00:00",
		minutes: 60,
		type: "video",
		vimeo: "463622731",
		selfHostedFilename: "PanelDiscussion_FutureHardwareWireless.mp4",
		main: `<span class="title">The Future of Hardware&nbsp;&amp; Wireless</span>
			Panel Discussion with
			<strong>Prof. Karlheinz Bock</strong> (5G&nbsp;Lab),
			<strong>Ritu Favre</strong> (NI),
			<strong>Dr. Ludger Verweyen</strong> (Infineon) and
			<strong>Thomas Riedel</strong> (Samsung Networks)
			`,
	},
	"Thu 11:00-1": {
		time: "2020-10-01T11:00:00",
		minutes: 5,
		type: "video",
		ieeeTVid: "173071",
		vimeo: "461802743",
		logo: "processed/vodfaone.png",
		main: `<span class="title">5G: A Technology Mix Improves our Lives</span>
		<span class="name">Gerhard Mack</span>
		<span class="aff">Vodafone Germany</span>`,
	},
	"Thu 11:00-2": {
		time: "2020-10-01T11:10:00",
		minutes: 1,
		type: "live-interactive",
		active: [
			{from: "2020-10-01T11:10:00", till: "2020-10-01T14:49:00"},
					],
		link: "https://robot.comnets.net",
		teaser: "462927218",
		teaserSelfHosted: "CatchMeifYouCan.mp4",
		logo: "processed/tu-dresden.jpg",
		main: `<span class="title">Catch Me if You Can</span>
		<div class="big">ONLINE DEMO</div>
		<span class="aff">TU&nbsp;Dresden //&nbsp;Deutsche Telekom Chair Communication Networks</span>`,
	},
	"Thu 11:20-1": {
		time: "2020-10-01T11:20:00",
		minutes: 6,
		type: "video",
		ieeeTVid: "172986",
		teaser: "461725184",
		vimeo:"461723112",
		lang: "de/en",
		logo: "processed/solve-for-tomorrow-5g-for-society.png",
		main: `<span class="title">Solve for Tomorrow –  5G for Society (Samsung Incubation Program)</span>
		<span class="name">Steffen Ganders</span>
		<span class="aff">Samsung</span>,
		<span class="name">Prof. Frank Fitzek</span>
		<span class="aff">5G&nbsp;Lab Germany</span> and
		<span class="name">Justin Gemeri</span>
		<span class="aff">EKIPA</span>`,

	},
	"Thu 11:20-2": {
		time: "2020-10-01T11:26:25",
		minutes: 2,
		type: "video",
		ieeeTVid: "",
		selfHostedFilename: "Grapes.mp4",
		logo: "processed/solve-for-tomorrow-5g-for-society.png",
		vimeo: "463327748",
		main: `<span class="title">GRAPES – Keep Your Device. We Give You the Power!</span>
		<span class="name">Jamie Burbano Villavicencio and Mobin Pourezza</span>
		<span class="aff">Grapes&nbsp;5G</span>`,
	},
	"Thu 11:20-3": {
		time: "2020-10-01T11:28:47",
		minutes: 5,
		type: "video",
		ieeeTVid: "",
		selfHostedFilename: "Hyperspecs.mp4",
		logo: "processed/solve-for-tomorrow-5g-for-society.png",
		vimeo: "463327868",
		main: `<span class="title">HYPERSPECS – AI Powered Industrial Safety</span>
		<span class="name">Diwaker Jha</span>
		<span class="aff">Hyperspecs</span>`,
	},
	"Thu 11:20-4": {
		time: "2020-10-01T11:34:22",
		minutes: 2,
		type: "video",
		ieeeTVid: "",
		vimeo: "463327473",
		selfHostedFilename: "Heartucate.mp4",
		logo: "processed/solve-for-tomorrow-5g-for-society.png",
		lang: "de",
		main: `<span class="title">HEARTUCATE – Connect, Create, Experience</span>
		<span class="name">Franziska Weser and Anna Kirchberg</span>
		<span class="aff">Heartucate</span>`,
	},
	"Thu 11:40-1": {
		time: "2020-10-01T11:40:00",
		minutes: 14,
		type: "video",
		ieeeTVid: "172961",
		vimeo: "461704673",
		teaser: "461704889",
		logo: "processed/samsung1.png",
		main: `<span class="title">Networks Tech Talk: Samsung Virtualized RAN Solutions</span>
		<span class="name">Shamik Shah and Tyler Kern</span>
		<span class="aff">Samsung</span>`,
	},
	"Thu 12:00-1": {
		time: "2020-10-01T12:00:00",
		minutes: 16,
		type: "video",
		ieeeTVid: "173056",
		vimeo: "461802472",
		teaser: "461802706",
		main: `<span class="title">Resilient Campus Networks</span>
		<span class="name">Dr. Philipp Schulz, Frank Bittner, Michael Karrenbauer and Dr. Lisa Underberg</span>
		<span class="aff">Industrial Radio Lab Germany</span>`,
	},
	"Thu 12:20-1": {
		time: "2020-10-01T12:20:00",
		minutes: 20,
		main: `<span class="break">Lunch Break</span>`,
	},
	"Thu 12:40-1": {
		time: "2020-10-01T12:40:00",
		minutes: 20,
		main: `<span class="break">Lunch Break</span>`,
	},
	"Thu 13:00-1": {
		time: "2020-10-01T13:00:00",
		minutes: 15,
		type: "video",
		ieeeTVid: "172951",
		teaser: "461704375",
		vimeo: "461703680",
		logo: "processed/nokia.png",
		main: `<span class="title">Industrial 5G Campus Networks</span>
		<span class="name">Martin Beltrop</span>
		<span class="aff">Nokia</span>`,
	},
	"Thu 13:00-2": {
		time: "2020-10-01T13:15:15",
		minutes: 1,
		type: "video",
		ieeeTVid: "",
		vimeo: "463454060",
		logo: "processed/tu-dresden.jpg",
		selfHostedFilename: "Rock-Paper-Scissors.mp4",
		main: `<span class="title">Rock-Paper-Scissors: Robotic Hand Control via Data Glove</span>
		<span class="aff">TU&nbsp;Dresden //&nbsp;Centre for Tactile Internet with Human-in-the-Loop</span>`,
	},
	"Thu 13:00-3": {
		time: "2020-10-01T13:17:05",
		minutes: 2,
		type: "video",
		ieeeTVid: "",
		logo: "processed/tu-dresden.jpg",
		selfHostedFilename: "CocktailRobot.mp4",
		vimeo: "463454543",
		main: `<span class="title">Human-Robot Collaboration - Cocktail Robot</span>
		<span class="aff">TU Dresden // Centre for Tactile Internet with Human-in-the-Loop</span>`,
	},
	"Thu 13:20-1": {
		time: "2020-10-01T13:20:00",
		minutes: 18,
		type: "video",
		ieeeTVid: "173246",
		vimeo: "462559555",
		main: `<span class="title">5G Deployment Synergies: Cross-border CAM corridors and Rural Coverage</span>
		<span class="name">Dr. Jorge Pereira</span>
		<span class="aff">European Commission</span>`,
	},
	"Thu 13:40-1": {
		time: "2020-10-01T13:40:00",
		minutes: 4,
		type: "video",
		ieeeTVid: "173061",
		vimeo: "461714678",
		logo: "processed/tu-dresden.jpg",
		main: `<span class="title">Meshmerize – Dynamic Network for Dynamic Systems</span>
		<span class="name">SreeKrishna Pandi</span>
		<span class="aff">TU&nbsp;Dresden //&nbsp;Meshmerize</span>`,
	},
	"Thu 13:40-2": {
		time: "2020-10-01T13:47:00",
		minutes: 4,
		type: "video",
		ieeeTVid: "173241",
		vimeo: "461713914",
		teaser: "461714055",
		logo: "processed/tu-dresden.jpg",
		main: `<span class="title">SENCE - Solution for Extended Network in Construction Environment</span>
		<span class="name">Jiajing Zhang and Ievgen Kharabet</span>
		<span class="aff">TU&nbsp;Dresden&nbsp;//&nbsp;Deutsche Telekom Chair Communication Networks</span>`,
	},
	"Thu 13:40-3": {
		time: "2020-10-01T13:54:00",
		minutes: 1,
		type: "video",
		ieeeTVid: "",
		selfHostedFilename: "5GCampusContainer.mp4",
		vimeo: "463324856",
		logo: "processed/tu-dresden.jpg",
		main: `<span class="title">5G Campus Container</span>
		<span class="aff">TU Dresden // Deutsche Telekom Chair for Communication Networks</span>`,
	},
	"Thu 14:00-1": {
		time: "2020-10-01T14:00:00",
		minutes: 12,
		type: "video",
		ieeeTVid: "",
		vimeo: "462920492",
		logo: "processed/ericsson.png",
		selfHostedFilename: "TheJourneyto6G.mp4",
		main: `<span class="title">The Journey to 6G</span>
		<span class="name">Dr. Magnus Frodigh</span>
		<span class="aff">Ericsson</span>`,
	},
	"Thu 14:20-1": {
		time: "2020-10-01T14:20:00",
		minutes: 25,
		type: "video",
		vimeo: "462423319",
		logo: "cospon_5Gpp_Lab.png",
		selfHostedFilename: "The5GLabGermanyVision.mp4",
		main: `<span class="title">The 5G Lab Germany Vision</span>
		<span class="name">Prof. Gerhard Fettweis and Prof. Frank Fitzek</span>
		<span class="aff">5G&nbsp;Lab&nbsp;Germany</span>`,
	},
	"Thu 14:40-1": {
		time: "2020-10-01T14:45:00",
		minutes: 15,
		main: `<span><span class="break">Coffee Break</span>`,
	},
	"Thu 15:00-1": {
		time: "2020-10-01T15:00:00",
		minutes: 60,
		type: "live-interactive",
		main: `<span class="title">The Future of Campus Networks&nbsp;&amp; Verticals</span>
			Panel Discussion with
			<strong>Dr. Ilaria Thibault</strong> (Vodafone),
			<strong>Dr. Jorge Pereira</strong> (European Commission),
			<strong>Dr. Norman Franchi</strong> (Advancing Individual Networks),
			<strong>Christian Piechnick</strong> (Wandelbots),
			<strong>Poldi Heidrich</strong> (Kuka) and
			<strong>Andreas Kwoczek</strong> (Formerly Volkswagen)
			`,
	},
	"Thu 16:30-1": {
		time: "2020-10-01T16:30:00",
		minutes: 60,
		type: "live-interactive",
		main: `<span class="title">Will there be&nbsp;6G?</span>
			Panel Discussion with
			<strong>Prof. Frank Fitzek</strong> (5G&nbsp;Lab),
			<strong>Prof. Gerhard Fettweis</strong> (5G&nbsp;Lab),
			<strong>Peter Riedel</strong> (Rohde&nbsp;&amp; Schwarz),
			<strong>Attila Bilgic</strong> (Krohne),
			<strong>Håkan Andersson</strong> (Ericsson) and
			<strong>Volker Ziegler</strong> (Nokia Bell Labs&nbsp;&amp; CTO)
			`,
	},
	"Thu 17:30-1": {
		time: "2020-10-01T17:30:00",
		minutes: 10,
		type: "live",
		main: `<span class="title">Closing Remarks</span>
		<span class="name">Prof. Gerhard Fettweis</span>
		<span class="aff">5G&nbsp;Lab&nbsp;Germany</span>`,
	},
};
