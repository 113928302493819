<template>

	<!-- Video Modal -->
	<div id="video-details" v-if="showmodal" class="modal fade in">
		<div ref="modal-backdrop" class="modal-backdrop fade" @click="closeModal()"></div>
		<div class="modal-container">
			<div class="modal-dialog bio-dialog video-modal" role="dialog">
				<div ref="modal-dialog" class="modal-content">

					<div class="scrollable">

						<div class="close-modal" @click="closeModal()">
							<div class="box">
								<div class="lr">
									<div class="rl">
									</div>
								</div>
							</div>
						</div>

						<div class="modal-body" style="padding:10px;">
							<!-- <h3>Teaser&nbsp;+ Full&nbsp;Video</h3> -->
							<div v-if="g.unlocked">
								<div class="title" style="display: flex; flex-direction: row; align-items: flex-start;"><div style="flex: 10 1 auto;" v-html="title"></div></div>
								<div class="time">{{timeDisplay}}</div>

								<div class="center-content">
									<div style="width: 100%; max-width: 45em;">
										<div class="info" v-show="name || affiliation">
											<div class="text">
												<div class="presenter" v-if="item.chair"><strong>Chair:</strong></h3></div>
												<div class="presenter" v-if="name" v-html="name"></div>
												<div class="aff" v-if="affiliation" v-html="affiliation"></div>
											</div>
										</div>
										<h3 v-if="vimeo||youtube">Video:</h3>
										<!-- Vimeo Full Video -->
										<div v-if="(vimeo || youtube) && g.limitedCountry" style="width: 100%;">
											<p>Unfortunately, the video is not available in your country.</p>
										</div>
										<div v-if="vimeo && !g.limitedCountry" style="width: 100%;">
											<div style="position: relative; overflow:hidden; padding-top: 56.25%; width: 100%">
												<iframe
													style="position:absolute;top:0;left:0;width:100%;height:100%"
													v-if="showmodal"
													:src="'https://player.vimeo.com/video/' + vimeo"
													frameborder="0" allow="autoplay; fullscreen; picture-in-picture"
													allowfullscreen></iframe>
											</div>
										</div>
										<div v-if="youtube && !g.limitedCountry" style="width: 100%;">
											<div style="position: relative; overflow:hidden; padding-top: 56.25%; width: 100%">
												<iframe
													style="position:absolute;top:0;left:0;width:100%;height:100%"
													v-if="showmodal"
													:src="'https://www.youtube-nocookie.com/embed/' + youtube"
													title="YouTube video player"
													frameborder="0"
													allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
													allowfullscreen>
												</iframe>
											</div>
										</div>
									</div>
								</div>
							</div>
							<unlock></unlock>
							<div style="width: 100%; text-align: center; font-size: 120%" v-if="!g.unlocked"><p style="text-align: center;">Participants of the conference have been provided the password by email.</p></div>
						</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-primary" tabindex="0" @click="closeModal()">Close</button>
						</div>

					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>

	import unlock from "../core/components/unlock.vue";


	export default {
		name: 'videomodal',
		props: {

		},
		components: {
			unlock: unlock,
		},
		data: function(){
			return {
				showmodal: false,
				item: {},

				title: "",
				timeDisplay: "",

				name: "",
				affiliation: "",

				vimeo: "",
				youtube: "",
			};
		},
		created: function(){

		},
		computed: {

		},
		methods: {
			openModal: function( item ){
				this.item = item
				this.title = item.title

				if (item.time) this.timeDisplay = (new Date(item.time)).toLocaleString('en-gb', { weekday: 'short', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' });

				this.name = "";
				this.affiliation = "";
				if (item.chair){
					this.name = item.chair.name;
					this.affiliation = item.chair.affiliation;
				} else {
					if (item.speaker) this.name = item.speaker;
					if (item.affiliation) this.affiliation = item.affiliation;
				}


				this.vimeo = item.vimeo || null;
				this.youtube = item.youtube || null;
				this.showmodal = true;

				document.body.classList.add("modal-open");

				let self = this;
				this.$nextTick(()=>{
					setTimeout(()=>self.$refs["modal-backdrop"].classList.add("in"),50);
				});

				document.addEventListener('keydown',this.handleKey);

			},
			handleKey: function(e){
				if (e.key == "Escape") this.closeModal();
			},

			closeModal: function () {
				this.showmodal = false;
				// this.$refs["speaker-modal"].style.display = "none";
				this.$refs["modal-backdrop"].classList.remove("in");
				document.body.classList.remove("modal-open");
				document.removeEventListener('keydown',this.handleKey);
			},
		},
	}

</script>

<style lang="less">

.modal-dialog.video-modal{
	.title{
		margin-top: 0.5em;
		font-size: 1.5em;
		padding-bottom: 8px;
		@media (max-width: 500px) {
			font-size: 1.2em;
		}
		border-bottom: 2px solid #e5e5e5;
		padding-bottom: 3px;

		padding-right: 1.3em;


		// &::before{
		// 	display: inline-block;
		// 	margin-right: 0.5em;
		// 	content: "\f008";
		// 	font-family: "FontAwesome";
		// }
	}
	.time{
		margin-top: 0.3em;
		color: gray;
		margin-bottom: 1em;
	}

	h3{
		font-size: 16px;
	}

	.info{
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;

		padding: 10px;
		margin-top: 0.5em;
		border-radius: 5px;
		// box-shadow: 0 0 5px 0px rgba(0,0,0,0.2);
		box-shadow: 0 0 1px 2px #18bc9c8c;

		@media (max-width: 500px) {
			flex-direction: column;
			align-items: flex-start;

		}

		flex-wrap: nowrap;
		.text{
			flex: 10 1 auto;
			.presenter,.aff{
				//margin-left: 1.5em;
			}
			.aff{
				font-weight: bold;
			}
		}

		.logo{
			flex: 0 0 auto;
			margin-left: 1em;
			img{
				max-height: 5em;
				max-width: 8em;
			 }
			@media (max-width: 500px){

				margin-top: 1em;
				//margin-bottom: 0.5em;
				margin-left: 1.5em;
			}
		}
	}

	.language{
		display: block;
		margin-top: 0.5em;
		color: gray;
		&::before{
			content: "\f028";
			font-family: "FontAwesome";
			margin-right: 0.5em;
		}
	}

	iframe,video{
		border: 1px solid #d5d5d5;
	}

}

.live,.live-interactive{
	display: block;
	color: #f5205a;
	margin-top: 0.4em;
	font-size: 0.9em;
	//font-weight: bold;
	&::before{
		content: "\f03d";
		font-family: "FontAwesome";
		margin-right: 0.5em;
	}

}
.live-interactive::after{
	content: "Live / Interactive"
}
.live::after{
	content: "Live"
}



</style>
