<template>
	<div style="margin-bottom: 2em">
		<h3 style="text-align: center; margin: 3em 0 1.2em 0;">Presented by</h3>
		<div class="sponsors-grid" style="display: flex; flex-wrap: wrap; align-items: center; justify-content: center; flex-shrink: 1;">
		<a href="https://ieee.org" target="_blank" class="raw"><img
			style="width: 206px; margin: 0.9em 1.5em; max-width: calc(100% - 1.6em)"
			src="img/sponsors/cospon_ieee-main.jpg" class="img-responsive" alt="IEEE"></a>
		<a href="https://comsoc.org/" target="_blank" class="raw"><img
			style="width: 194px; margin: 0.9em 1.5em;max-width: calc(100% - 1.6em)"
			src="img/sponsors/cospon_ieee-comsoc.jpg" class="img-responsive" alt="IEEE Communications Society"></a>
		<a href="https://mtt.org/" target="_blank" class="raw"><img
			style="width: 150px; margin: 0.9em 1.5em;max-width: calc(100% - 1.6em)"
			src="img/sponsors/cospon_mtts.png" class="img-responsive" alt="IEEE Microwave Theory & Technology Society"></a>
		<a href="https://www.vde.com/de/itg" target="_blank" class="raw"><img
			style="width: 260px; margin: 0.9em 1em;max-width: calc(100% - 1.6em)"
			src="img/sponsors/cospon_vde-itg.png" class="img-responsive" alt="VDE - Informationstechnische Gesellschaft"></a>
		<a href="https://5glab.de" target="_blank" class="raw"><img
			style="width: 230px; margin: 0.7em 1.5em 0.7em 1.2em; max-width: calc(100% - 0.8em)"
			src="img/sponsors/cospon_5Gpp_Lab1x.png"
			srcset="img/sponsors/cospon_5Gpp_Lab1x.png 1x, img/sponsors/cospon_5Gpp_Lab2x.png 2x"
			class="img-responsive" alt="5G Lab Germany"></a>
		<a href="https://www.barkhauseninstitut.org" target="_blank" class="raw"><img
			style="width: 220px; margin: 0.9em 1.5em;max-width: calc(100% - 1.6em)"
			src="img/sponsors/cospon_bi.svg" class="img-responsive" alt="Barhausen Institut"></a>
		<a href="https://tu-dresden.de" target="_blank" class="raw"><img
			style="width: 230px; margin: 0.7em 1.5em; max-width: calc(100% - 0.8em)"
			src="img/sponsors/TUD.png"
			class="img-responsive" alt="TU Dresden"></a>
		<a href="https://www.oulu.fi/en" target="_blank" class="raw"><img
			style="width: 120px; margin: 0.9em 1.5em;max-width: calc(100% - 1.6em)"
			src="img/sponsors/cospon_uni-oulu.png"
			class="img-responsive" alt="ISAC"></a>
		<a href="https://www.imec-int.com/en" target="_blank" class="raw"><img
			style="width: 200px; margin: 0.7em 1.5em; max-width: calc(100% - 0.8em)"
			src="img/sponsors/cospon_imec.png"
			class="img-responsive" alt="IMEC"></a>
		<a href="https://isac.committees.comsoc.org/" target="_blank" class="raw"><img
			style="width: 160px; margin: 0.7em 0.2em; max-width: calc(100% - 0.8em)"
			src="img/sponsors/cospon_isac.png"
			class="img-responsive" alt="ISAC"></a>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'cosponsors',
	}

</script>

<style lang="less">

</style>
