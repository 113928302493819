<template>

	<div class="ruler">
		<div class="ru"></div>
		<div v-if="faClasses" aria-hidden="true" class="sym" :class="faClasses"></div>
		<div v-if="!faClasses" class="ru short"></div>
		<div class="ru"></div>
	</div>

</template>

<script>

	export default {
		name: 'faruler',
		created: function(){},
		props: {
			faclass: String,
		},
		computed: {
			faClasses: function(){
				if (this.faclass) return this.faclass;
				if (!this.g.config.faRulerSymbol) return "fa fa-star";
				if (this.g.config.faRulerSymbol == "none") return false;
				else return (this.g.config.faRulerSymbol);
			}
		}
	}

</script>

<style lang="less">
	.ruler{
		margin-top: 0.8em;
		margin-bottom: 3.8em;
		display: flex;
		flex-direction: row;
		width: 100%;
		align-items: center;
		justify-content: center;
		.sym{
			flex: 0 0 auto;
			margin-left: 0.25em;
			margin-right: 0.25em;
			font-size: 2em;
		}
		.ru{
			flex: 0 0 auto;
			width: 6.7em;
			height: 0;
			border-bottom: 5px solid;
		}

	}
</style>
