<template>
	<div>
		<a v-if="en" v-bind:[linkAttr]="url" v-on:[getEvent].prevent.stop="openModal()">
		   <div class="s-entry" :class="{
			   		'highlight': isNow,
					'recording-available': recordingAvailable,
					'isactive': isActive, }"
					:[tabindexAttr]="0" >

				<!-- Local Times -->
				<span ref="localTime" v-if="g.showLocalTimes" class="local-time fade">{{localDateTime}}</span>

				<!-- Main Display -->
				<div v-html="en.main"></div>

				<!-- Language -->
				<span v-if="en.type && en.type == 'video' && en.lang=='de'" class="language">German</span>
				<span v-if="en.type && en.type == 'video' && en.lang=='de/en'" class="language">German; English subtitles</span>

				<!-- Video Duration -->
				<span v-if="en.type && en.type == 'video'" class="video duration">{{Math.round(en.minutes)}}</span>

				<!-- Type -->
				<span v-if="en.type" :class="{'live': en.type=='live','live-interactive': en.type=='live-interactive'}"></span>

				<!-- Recording Available -->
				<span v-if="recordingAvailable" class="recording"> watch again</span>

			</div>
		</a>
		<videomodal ref="modal" v-if="en && recordingAvailable" :sentry="en"></videomodal>
		<div v-if="!en" class="error">
			Could not find schedule entry <br>'{{ekey}}'!
		</div>
	</div>

</template>

<script>
	import videomodal from "./videomodal.vue";

	export default {
		name: 'sentry',
		props: {
			ekey: String,
			isNowUrl: String,
		},
		components: {
			videomodal: videomodal,
		},
		mounted: function(){
		},
		updated: function(){
			if (this.g.showLocalTimes){
				let self = this;
				this.$nextTick(()=>{

					setTimeout(() => {
						self.$refs.localTime.classList.add("in");
					}, 50);
				});
			}
		},
		computed: {
			url: function(){
				if (this.isNowUrl) return this.isNowUrl;
				return this.g.config.currentScheduleItemLinksTo;
			},
			en: function(){
				let res = this.g.schedule[this.ekey];
				if (!res) console.error("sentry with key '", this.ekey, "' does not exist.");
				return res;
			},
			isNow: function(){
				return this.g.schedule.isNow(this.en);
			},
			hasPassed: function(){
				return this.g.schedule.hasPassed(this.en);
			},
			isActive: function(){
				return this.g.schedule.isActive(this.en);
			},
			localDateTime: function(){
				return this.g.schedule.localDateTime(this.en);
			},
			recordingAvailable: function(){
				return this.hasPassed && ((this.g.limitedCountry && (this.en.ieeeTVid || this.en.selfHostedFilename) ) || (!this.g.limitedCountry && this.en.vimeo) );
			},
			linkAttr: function(){
				if (this.isNow) return "href";
				return null;
			},
			tabindexAttr: function(){
				if (this.recordingAvailable) return "tabindex";
				return null;
			},
			getEvent: function(){
				if (this.recordingAvailable) return "click";
				return null;
			}
		},
		methods: {
			openModal: function(){
				this.$refs["modal"].openModal(this.en);
			}
		}
	}

</script>

<style lang="less">

a {
	color: unset !important;

	.s-entry{
		transition: all 200ms ease-out;


		&.not-in-search{
			opacity: 0.5;
		}


		&.recording-available{
			background-color: #18bc9c10;
			cursor: pointer;
			padding: 8px;
			border-radius: 5px;

			&:hover, &:focus, &:active{
				box-shadow: 0px 0px 0px 6px #1abc9b88;
			}
		}

		&.highlight{

			background-color: #18bc9c70;
			padding: 0.8rem;
			border-radius: 5px;
			cursor: pointer;
			// box-shadow: 0 0px 1px 1px rgba(0,0,0,0.2);

			&::before{
				content: "now";
				color: #18bc9c;
				//font-variant: small-caps;
				font-size: 1.8rem;
				font-weight: bold;
				display: block;
				position: absolute;
				bottom: calc(100% - 4px);
			}

			&:hover, &:active, &:focus{
				box-shadow: 0 0px 0px 6px rgba(200,250,200,0.8);
			}

			.video.duration{
				color: black;
			}
		}

		&.isactive{


			background: #2b628c40;
			// border-radius: 50%;
			// margin: 10px;
			// height: 20px;
			// width: 20px;

			box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
			transform: scale(1);
			animation: pulse 3s infinite;
			padding: 10px;
			border-radius: 5px;
			cursor: pointer;

			&::before{
				content: "active";
				color: #f5205a;
				//font-variant: small-caps;
				font-size: 1.8rem;
				font-weight: bold;
				display: block;
				position: absolute;
				bottom: calc(100% - 4px);
			}

			@keyframes pulse {
				0% {
					//transform: scale(0.95);
					box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
				}

				70% {
					//transform: scale(1);
					box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
				}

				100% {
					//transform: scale(0.95);
					box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
				}
			}

		}


	}

	.local-time{
		display: block;
		margin-bottom: 0.3em;
	}


	.title{
		font-size: 1.2em;
		display: block;
		&::before, &::after{
			content: "\"";
		}
		margin-bottom: 0.4em;
	}
	.name::after{
		content: " - "
		//font-size: 0.9em;
	}
	.aff{
		font-size: 0.9;
		font-weight: bold;
	}
	.language{
		display: block;
		// color: #aaa;
		font-size: 0.9em;
		margin-top: 0.4em;

		&::before{
			content: "\f028";
			font-family: "FontAwesome";
			margin-right: 0.5em;
		}
	}

	.video.duration{
		display: block;
		color: #aaa;
		margin-top: 0.4em;
		font-size: 0.9em;
		//font-weight: bold;
		&::before{
			content: "\f008";
			font-family: "FontAwesome";
			margin-right: 0.5em;
		}
		&::after{
			content: " min."
		}
	}

	.recording{
		display: block;
		color: gray;
		&::before{
			display: inline-block;
			content: '';
			width: 10px;
			height: 10px;
			border-radius: 50%;
			background-color: #1abc9b;
			margin-right: 0.1em;
		}
	}

	.break{
		font-size: 1.2em;

	}



	.s-entry.shighlight{
		box-shadow: 0px 0px 5px 2px #64b36e;
		background-color: #befdb954;
	}
}

.live,.live-interactive{
	display: block;
	color: #f5205a;
	margin-top: 0.4em;
	font-size: 0.9em;
	//font-weight: bold;
	&::before{
		content: "\f03d";
		font-family: "FontAwesome";
		margin-right: 0.5em;
	}

}
.live-interactive::after{
	content: "Live / Interactive"
}
.live::after{
	content: "Live"
}

.error{
	font-size: 2em;
	color: red;
}

</style>
