
// Data Structure:

// {
// 		title: "Opening",
// 		start: "",
// 		end: "",
// 		break: true, 		// <- true | false
// 		vimeo: "",  // <- "vimeoID"
// 		youtube: "", // <- "youtubeID"
// 		slides: "filename.pdf", // <- "Filename Slides"
// 		slides: [ // <- Multiple slides
// 			{
// 				name: "Title",
// 				file: "Filename.pdf"
// 			},
// 			...
//      ],
// 		chair: {
// 			name: "Andre Bourdoux",
// 			affiliation: "IMEC, Belgium"
// 		},
// 		papers: [
// 			{
// 				"title": "The Paper Title",
// 				"authors": "A. Bourdoux, T. Ußmüller",
// 				"ptype": "virtual"
// 			},
// 			{
// 				"title": "Yet Another Paper Title",
// 				"authors": "J. Doe, J. Smith",
// 				"ptype": "physical"
// 			},
// 		],
// 		speaker: "Fabian Diehm",
// 		affiliation: "TU Dresden, Dresden, Germany",
// 		ptype: "virtual", 	// <- "physical" | "virtual"
// 		misc: "", 			// <- any html possible
// },


export const programdata = [
	{
		title: "Day 1",
		date: "2023-03-19",
		slidefolder: "/slides/2023/AE091EGS",
		items: [
			{
				title: "Registration and welcome coffee",
				start: "8:30",
				end: "9:00",
				break: true,
			},
			{
				title: "Tutorial 1: Integrated Sensing and Communications: Fundamental Limits, Signal Design, and Emerging PHY Technologies",
				start: "9:00",
				end: "10:30",
				speaker: "Yifeng Xiong (BUPT), Fan Liu (SUSTech), George C. Alexandropoulos (NKUA)",
			//	chair: {
			//		name: "... ",
			//		affiliation: "IMEC, Belgium"
			//	},
				slides: "JC&S_Tutorial 1.pdf",
				vimeo: "928690369",
				misc: `
					<div style="margin-top: 0.5em; font-size: 0.9em; font-style: italic;">
						Abstract: Jointly suggested by recent advances from the communications and signal processing communities, a common theme in many perspectives is that 6G RAN should become multi-functional. It should serve as edge infrastructure to provide site-specific services for surrounding users, rather than communication-only functionality. It is envisioned that radio sensing functionality can be integrated into 6G RAN in a low-cost and fast manner. Therefore, the future cellular network could image and measure the surrounding environment to enable advanced location-aware services. This type of research is typically referred to as Integrated Sensing and Communications (ISAC), which finds applications in numerous emerging areas, including vehicular networks, environmental monitoring, and human activity recognition. Recently, ISAC has garnered recognition from the International Telecommunication Union (ITU) as a pivotal element within the 6G landscape, serving as one of six key 6G usage scenarios. In this half-day tutorial, we will firstly overview the background and application scenarios of ISAC. As a step further, we will introduce the state-of-the-art research progress on this topic, which consists of 3 technical parts: 1) Information-Theoretical Limits, 2) Signal Design for ISAC, and 3) ISAC with Emerging PHY Technologies. Finally, we will conclude the tutorial by summarizing the future directions and open problems in the area of ISAC.
					</div>`
			},
			{
				title: "15min Break",
				start: "10:30",
				end: "10:45",
				break: true,
			},
			{
				title: "Tutorial 2: Joint Communication and Radio Sensing: RF Hardware opportunities and challenges - A circuit and system level perspective",
				start: "10:45",
				end: "12:15",
				speaker: "Armen Harutyunyan (BI), Padmanava Sen (BI)",
			//	chair: {
			//		name: "Prof. Marco Di Renzo",
			//		affiliation: "Paris-Saclay University, France"
			//	},
				slides: "Tutorial 2.pdf",
				vimeo: "928690610",
				misc: `<div style="margin-top: 0.5em; font-size: 0.9em; font-style: italic;">
					Abstract: This tutorial focuses on the topic of joint communication and radio sensing (JC&S),  its applications in the scope of upcoming 6G technology. Different full-duplex applications in terms of complexity and implementation will be presented. Potential frontend candidates for the JC&S scenarios will be discussed. Different self-interference cancellation techniques in antenna, RF/analog frontend and digital baseband domain will be presented. The system budget of a practical 5G transceiver will be highlighted, followed by the discussion on the system level simulations of communication and sensing scenarios of the JC&S frontend. Insights on system level and measurement validation using specific testbeds will be given.
				</div>`
			},
			{
				title: "Lunch Break",
				start: "12:15",
				end: "13:00",
				break: true,
			},
			{
				title: "Tutorial 3: Joint Communication and Human Sensing: From detection, localization and tracking, to pose and vital sign estimation",
				start: "13:00",
				end: "14:30",
				speaker: "Sofie Pollin (KUL), Yang Miao (UTwente), Zhuangzhuang Cui (KUL)",
			//	chair: {
			//		name: "Prof. Visa Koivunen",
			//		affiliation: "Aalto University, Finland"
			//	},
				slides: "Tutorial 3.pdf",
				vimeo: "928690673",
				misc: `<div style="margin-top: 0.5em; font-size: 0.9em; font-style: italic;">
					Abstract: Towards human-centric networks, the key requirement lies in how technology can serve people, not just providing high-data-rate connectivity, but also taking care of people’s health and saving people’s lives. In this context, joint communication and sensing (JCAS) technology is gaining more attention, potentially enabling comprehensive contact-free human sensing like conventional radars while guaranteeing simultaneous connectivity. We propose this tutorial to introduce and discuss the state of the art and challenges of human-centric JCAS technologies. We define the use cases and scenarios of JCAS with the presence of human, and discuss the foundations of methodologies for contact-free detection, localization, and tracking of person(s), as well as poses and vital signs estimation, covering system configuration, channel modeling, and signal processing. 
				</div>`
			},
			{
				title: "Coffee Break",
				start: "14:30",
				end: "14:45",
				break: true,
			},
			{
				title: "Tutorial 4: Fundamentals and System Design of Radio Localization in Ground-Air-Space Networks",
				start: "14:45",
				end: "16:15",
				speaker: "Hazem Sallouha (KUL), Sharief Essam Saleh (Chalmers), Sofie Pollin (KUL), Henk Wymeersch (Chalmers)",
			//	chair: {
			//		name: "Prof. Mikko Valkama",
			//		affiliation: "Tampere University, Finland"
			//	},
				slides: "Tutorial 4.pdf",
				vimeo: "928690795",
				misc: `<div style="margin-top: 0.5em; font-size: 0.9em; font-style: italic;">
					Abstract: The inherent limitations in scaling up ground infrastructure for future wireless networks, combined with decreasing operational costs of aerial and space networks, are driving considerable research interest in multisegment ground-air-space (GAS) networks. In GAS networks, where ground and aerial users share network resources, ubiquitous and accurate user localization becomes indispensable, not only as an end-user service but also as an enabler for location-aware communications. This breaks the convention of having localization as a byproduct in networks primarily designed for communications. To address these imperative localization needs, the design and utilization of ground, aerial, and space anchors require thorough  investigation. This tutorial aims to provide attendees with an in-depth systemic analysis of the radio localization problem in GAS networks, covering key design aspects in the state-of-the-art of ground and aerial user localization. In particular, the main objectives are to 1) define the key characteristics of anchors and targets in GAS networks, 2) detail localization fundamentals in GAS networks, considering 3D positions and orientations, 3) analyze radio localization systems in GAS networks, detailing the system model, design aspects, and considerations for each of the three GAS anchors, and 4) identify the vital roles 6G enablers are expected to play in radio localization in GAS networks.
				</div>`
			},
			{
				title: "15min Break",
				start: "16:15",
				end: "16:30",
				break: true,
			},
			{
				title: "Tutorial 5: Joint Communication and Sensing for Space: Understanding the Convergence of Space Functions through employing repurposable payloads",
				start: "16:30",
				end: "18:00",
				speaker: "Adam Kapovits (Eurescom), Helmut Zaglauer (Airbus), Jung‐Hyo Kim (Airbus), Marius Corici (FhG‐fokus), Cristian Grozea (FhG‐fokus)",
			//	chair: {
			//		name: "Prof. Markku Juntti",
			//		affiliation: "University of Oulu, Finland"
			//	},
				slides: "Tutorial 5.pdf",
				vimeo: "928690868",
				misc: `<div style="margin-top: 0.5em; font-size: 0.9em; font-style: italic;">
					Abstract:  Joint (or Integrated) Communication and Sensing (JC&S) has become a very hot  topic gaining plenty of attention in recent years in particular in the context of the evolution of 5G mobile telecommunications networks towards Beyond 5G (B5G) and 6G. Currently the JC&S applications that are being discussed are almost entirely in the terrestrial domain. However, there are already some significant efforts to address Joint Communication and Sensing using space-based assets or infrastructure as both sensing and communication are part of what is already offered by space-borne infrastructures. Recently for instance the use of MIMO LEO satellite systems with a beam squint-aware precoding scheme to operate communications and target sensing applications simultaneously has been proposed. But there are also many other potential applications where RF sensing from space and 5G Non-terrestrial network (NTN) communications can align to provide new capabilities and services. The emergence of 5G NTN in 3GPP standardization and the expectation that it will be an integral part of 6G necessitates a more thorough investigation of potential commonalities and synergies between JC&S and NTN. The convergence of space functions promises a much more efficient use of space assets with increasing business viability. The tutorial will provide the basic elements to understand the requirements, system concepts and potential benefits of Joint Communication and Sensing from Space.
				</div>`
			},
			{
				title: "Welcome Reception",
				start: "18:00",
				end: "20:00",
				break: true,
			}
		]
	},
	{
		title: "Day 2",
		date: "2023-03-20",
		slidefolder: "/slides/2023/AE091EGS",
		items: [
			{
				title: "Registration and Welcome Coffee",
				start: "8:30",
				end: "8:45",
				break: true,
			},
			{
				title: "Conference Opening - Welcome Address",
				speaker: "André Bourdoux (imec), Prof. Gerhard Fettweis (TU Dresden)",
				start: "8:45",
				end: "9:00",
				slides: "Opening-slides.pdf",
				vimeo: "928690975",
			},
			{
				title: "Keynote: Physical Layer Security for Dual‐function Radar‐Communication Systems",
				speaker: "Athina Petropulu",
				affiliation: "Rutgers",
				start: "9:00",
				end: "9:40",
				slides: "Petropulu-slides.pdf",
				vimeo: "928690995",
			},
			{
				title: "Keynote: Cyber‐Physical Continuum: Towards Immersive XR using JC&S",
				speaker: "Jeroen Famaey",
				affiliation: "imec",
				start: "9:40",
				end: "10:20",
				slides: "famaey-slides.pdf",
				vimeo: "928691044",
			},
			{
				title: "15min Coffee Break",
				start: "10:20",
				end: "10:35",
				break: true,
			},
			{
				title: "Topical paper session 1: Channel Modeling and Measurement for JC&S (5 papers)",
				speaker: "Sofie Pollin",
				affiliation: "KUL",
				start: "10:35",
				end: "12:05",
				papers: [
					{
						"title": "D‐Band Channel Modelling by 3D Ray Tracing for Joint Communications and Sensing",
						"authors": "Yunqi Feng (Ghent University & IMEC, Belgium); Wout Joseph (Ghent University/IMEC, Belgium); Emmeric Tanghe (Ghent University, Belgium); Andre Bourdoux (IMEC, Belgium); Mamoun Guenach (Imec, Leuven, Belgium)",
					},
					{
						"title": "Sounding‐Based Evaluation of Multi‐Sensor ISAC Networks for Drone Applications: Measurement and Simulation Perspectives",
						"authors": "Julia Beuster (Technische Universität Ilmenau & Fraunhofer Institute for Integrated Circuits IIS, Germany); Carsten Jan Smeenk (Fraunhofer Institute for Integrated Circuits & TU Ilmenau, Germany); Saw James Myint, Reza Faramarzahanagri, Carsten Andrich, Sebastian Giehl and Christian Schneider (Technische Universität Ilmenau, Germany); Reiner S. Thomä (Ilmenau University of Technology, Germany)",
					},
					{
						"title": "Fusion of Active and Passive Measurements for Robust and Scalable Positioning",
						"authors": "Hong Zhu, Alexander Venus and Erik Leitinger (Graz University of Technology, Austria); Stefan Tertinek (NXP Semiconductors, Austria); Klaus Witrisal (Graz University of Technology, Austria)"
					},
					{
						"title": "Static Human Position Classification from Indoor mmWave Radio RSSI Measurements",
						"authors": "Silvia Zampato (University of Padova, Italy); Praneeth Susarla (University of Oulu, Finland); Markku Jokinen (University of Oulu & Centre for Wireless Communications, Finland); Nuutti Tervo and Marko E Leinonen (University of Oulu, Finland); Miguel Bordallo Lopez (University of Oulu, Finland & VTT Technical Research Centre of Finland Ltd., Finland); Markku Juntti (University of Oulu, Finland); Michele Rossi (University of Padova, Italy); Olli Silvén (University of Oulu, Finland)",
					},
					{
						"title": "Multistatic Sensing Performance Maps for Evaluating Integrated Sensing and Communication Deployments",
						"authors": "Christopher Mollén (Ericsson AB, Sweden); Jörg Huschke (Ericsson, Germany); Robert Baldemair (Ericsson AB & Ericsson Research, Sweden); Gabor Fodor (Ericsson Research & Royal Institute of Technology (KTH), Sweden)",
					},
				],
				vimeo: "928691082",
			},
			{
				title: "Business Lunch",
				start: "12:05",
				end: "13:00",
				break: true,
			},
			{
				title: "Topical paper session 2: Demos and Experiments for JC&S (5 papers)",
				start: "13:00",
				end: "14:30",
				chair: {
 				name: "Fan Liu",
 				affiliation: "SUSTech"
				},
				papers: [
					{
						"title": "Circularly Polarized Dual Antenna with Crescent Axed Sporadic Meander Traces Isolated by Shorting Pins and Defected Ground for Wi‐Fi Sensing",
						"authors": "Shahanawaz Kamal and Jan Adler (Barkhausen Institut, Germany); Maximilian Matthe (Barkhausen Institute, Germany); Padmanava Sen (Research Group Leader, Barkhausen Institut gGmbH, Germany)",
					},
					{
						"title": "People Flow Estimation with a Wi‐Fi‐Based Passive Radar",
						"authors": "Laurent Storrer (Univesité Libre de Bruxelles (ULB), Belgium); Dejvi Cakoni (Université Libre de Bruxelles, Belgium); Hasan Can Yildirim (Université Libre de Bruxelles (ULB), Belgium); Martin Willame (Université Catholique de Louvain (UCLouvain) & Université Libre de Bruxelles (ULB), Belgium); Jerome Louveaux (Université catholique de Louvain, Belgium); Philippe De Doncker (ULB, Belgium); Sofie Pollin (KU Leuven, Belgium); François Horlin (Université Libre de Bruxelles, Belgium))",
					},
					{
						"title": "A Digital Twinning Platform for Integrated Sensing, Communications and Robotics",
						"authors": "Vlad Costin Andrei (Technische Unversität München, Germany); Xinyang Li and Maresa Fees (Technical University of Munich, Germany); Andreas Feik (ETH Zurich, Switzerland); Ullrich J Mönich (Technische Universität München, Germany); Holger Boche (Technical University Munich, Germany)"
					},
					{
						"title": "Gesture Detection Using IEEE 802.15.4z Compliant Impulse Radio UWB Radar System",
						"authors": "Amirashkan Farsaei and Bernard Meyer (IMEC, The Netherlands); Liwei Zhou (Eindhoven University of Technology, The Netherlands); Alireza Sheikh (IMEC Nederland, The Netherlands); Gururaja Kasanadi Ramachandr and Utku Kumbul (IMEC, The Netherlands); Mohieddine El Soussi (Imec, The Netherlands); Peng Zhang (IMEC the Netherlands, The Netherlands)",
					},
					{
						"title": "Sensing in the Third Dimension: UWB‐Based Sensing in Urban Canyons",
						"authors": "Fabian de Ponte Müller, Martin Schmidhammer, Christian Gentner, Ibrahim Rashdan and Stephan Sand (German Aerospace Center (DLR), Germany)",
					},
				],
				vimeo: "928691155",
			},
			{
				title: "Demo teasers",
				start: "14:30",
				end: "14:35",
				vimeo: "928691217"
			},
			{
				title: "Demo Show + Coffee Break",
				start: "14:35",
				end: "15:20",
				break: true,
			},

			{
				title: "Topical paper session 3: Fundamental Theory and Performance Analysis for JC&S (4 papers)",
				start: "15:20",
				end: "16:35",
				chair: {
 				name: "Markku Juntti",
 				affiliation: "UOULU"
				},
				papers: [
					{
						"title": "Joint Communication and State Sensing Under Logarithmic Loss",
						"authors": "Hamdi Joudeh (Eindhoven University of Technology, The Netherlands); Giuseppe Caire (Technische Universität Berlin, Germany)",
					},
					{
						"title": "Trellis Shaping for Joint Communications and Sensing: A Duality to PAPR Mitigation",
						"authors": "Husheng Li (Purdue University, USA); Zhu Han (University of Houston, USA); H. Vincent Poor (Princeton University, USA)",
					},
					{
						"title": "Enhancing Frequency Hopping Security in ISAC Systems: A Physical Layer Security Approach",
						"authors": "Ali Khandan Boroujeni (Barkhausen Institute GmbH Dresden, Germany); Ghazal Bagheri (Dresden, University of Technology, Germany); Stefan Köpsell (TU Dresden, Germany)"
					},
					{
						"title": "Robustness of ISAC Waveforms to Power Amplifier Distortion",
						"authors": "Abdur Rahman Mohamed Ismail (University of Ghent & IMEC, Belgium); Mamoun Guenach (Imec, Leuven, Belgium); Adham Sakhnini (Imec, Belgium); Andre Bourdoux (IMEC, Belgium); Heidi Steendam (Ghent University, Belgium)",
					},
				],
				vimeo: "928691230",
			},
			{
				title: "Panel: The path of JC&S towards 6G standardization what will be the role of academia and industry for the next 5 years?",
				chair: {
					name: "Prof. Gerhard Fettweis and Andre Bourdoux",
					affiliation: "BI and imec"
				},
				speaker: "Richard Stirling‐Gallacher (Huawei), Marja Matinmikko‐Blue (UOULU), Sofie Pollin (KUL)",
				start: "16:35",
				end: "17:35",
				slides: [ 
					{
						name: "Intro Richard Stirling-Gallacher", 
						file: "panel_stirling-gallacher.pdf"
					}, 
					{
						name: "Intro Marja Matinmikko-Blue", 
						file: "panel_matinmikko-blue.pdf"
					},
					{
						name: "Intro Sofie Pollin", 
						file: "panel_pollin.pdf"
					},
			//		{
			//			name: "Intro Pandharipande", 
			//			file: "panel_pandharipande.pdf"
			//		},
				],
				vimeo: "928691280",
			},
			{
				title: "Social Dinner @DeHoorn",
				misc: `
					<div style="margin-top: 0.5em; font-size: 0.9em; font-style: italic;">
						Please notice: The busses are leaving at 17:35 at the venue entrance.
					</div>`,
				start: "18:00",
				break: true,
			},


		]
	},
	{
		title: "Day 3",
		date: "2023-03-21",
		slidefolder: "/slides/2023/AE091EGS",
		items: [
			{
				title: "Morning Coffee",
				start: "8:30",
				end: "9:00",
				break: true,
			},
			{
				title: "Keynote: Opportunistic Joint Communications and Sensing",
				speaker: "Marco Lops",
				affiliation: "Unina",
				start: "09:00",
				end: "09:40",
				slides: "lops-slides.pdf",
				vimeo: "928691393",
			},
			{
				title: "Keynote: UWB Communication, Ranging, and Sensing",
				speaker: "Frank Leong",
				affiliation: "NXP",
				start: "09:40",
				end: "10:20",
				slides: "leong-slides.pdf",
				vimeo: "928691432",
			},
			{
				title: "15min Break",
				start: "10:20",
				end: "10:35",
				break: true,
			},
			{
				title: "Topical paper session 4: Algorithm Design for JC&S (6 papers)",
				start: "10:35",
				end: "12:25",
				chair: {
 					name: "Ahmad Nimr",
 					affiliation: "TU Dresden, Germany"
				},
				papers: [
					{
						"title": "Successive Threshold‐Based Multipath Mitigation Aided by Neural Network for UWB Ranging",
						"authors": "Alireza Sheikh (IMEC Nederland, The Netherlands); Bo Yang (Eindhoven University of Technology, The Netherlands); Mohieddine El Soussi (Imec, The Netherlands); Amirashkan Farsaei (IMEC, The Netherlands); Peng Zhang (IMEC the Netherlands, The Netherlands)",
					},
					{
						"title": "A Diagonal Algorithm with Low Complexity for Joint Range, Velocity, and Angle Estimation",
						"authors": "Yi Geng (Cict Mobile, China)",
					},
					{
						"title": "Angle‐Of‐Departure Estimation Algorithms for True‐Time‐Delay Antenna Arrays with Software‐Defined Radio",
						"authors": "Carl R. Collmann (TU Dresden, Germany); Ana Belen Martinez, Ahmad Nimr and Gerhard P. Fettweis (Technische Universität Dresden, Germany)",
					},
					{
						"title": "Bi‐Static Sensing with 5G NR Physical Uplink Shared Channel Transmission",
						"authors": "Visa Tapio, Nuutti Tervo, Marko E Leinonen and Aarno Pärssinen (University of Oulu, Finland)",
					},
					{
						"title": "CSI4Free: GAN‐Augmented mmWave CSI for Improved Pose Classification",
						"authors": "Nabeel Nisar Bhat (University of Antwerp - Imec, Belgium & Imec, Belgium); Rafael Berkvens (University of Antwerp - imec, Belgium); Jeroen Famaey (University of Antwerp & Imec, Belgium)",
					},
					{
						"title": "On Filtering and Complexity Reduction for Subspace‐Based Superresolution Path Delay Estimation Algorithms",
						"authors": "Zhongju Li, Ahmad Nimr, Philipp Schulz and Gerhard P. Fettweis (Technische Universität Dresden, Germany)",
					},
				],
				vimeo: "928691470",
			},
			{
				title: "Business Lunch",
				start: "12:25",
				end: "13:25",
				break: true,
			},
		
			{
				title: "Topical paper session 5: Waveform and Signaling Design for JC&S (5 papers)",
				start: "13:25",
				end: "14:55",
				chair: {
 				name: "Utku Kumbul",
 				affiliation: "imec-nl"
				},
				papers: [
					{
						"title": "Symbol‐Level Precoding for Multi‐Cell ISAC",
						"authors": "Nithin Babu (UCL, United Kingdom (Great Britain)); Christos Masouros (University College London, United Kingdom (Great Britain))",
					},
					{
						"title": "Automotive Radar Interference Mitigation Using Phase‐Coded FMCW Waveform",
						"authors": "Utku Kumbul (IMEC, The Netherlands); Nikita Petrov (Delft University of Technology, The Netherlands); Cicero S Vaucher (NXP Semiconductors, Eindhoven & Delft University of Technology, The Netherlands); Alexander Yarovoy (TU Delft, The Netherlands)",
					},
					{
						"title": "Design of Communication Signal Processing for LFM‐CPM Joint Communications and Sensing with Circulating Coding",
						"authors": "Bohan Liu, Jie Li, Wei Wang and Jiameng Bi (Nanjing University of Aeronautics and Astronautics, China)",
					},
					{
						"title": "An Optimized CSS‐ISAC Signal Design for Localisation with LEO Satellites",
						"authors": "Daniel Egea-Roca and José A. López-Salcedo (Universitat Autònoma de Barcelona, Spain); Gonzalo Seco-Granados (Universitat Autonoma de Barcelona, Spain)",
					},
					{
						"title": "A Novel Adaptive Spreading Waveform for Integrated Sensing and Communication",
						"authors": "Daljeet Singh (University of Oulu, Finland & Lovely Professional University, India); Atul Kumar (Indian Institute of Technology (BHU), Varanasi, India); Hem Joshi (Thapar University, India); Ashutosh Singh (Thapar Institute of Engineering and Technology, India); Mariella Särestöniemi (University of Oulu & Research Unit of Health Sciences and Technology and Center for Wireless Communication, Finland); Teemu Samuli Myllylä (University of Oulu, Finland); Maurizio Magarini (Politecnico di Milano, Italy))",
					},
				],
				vimeo: "928691644",
			},
			{
				title: "15min Break",
				start: "14:55",
				end: "15:10",
				break: true,
			},
			{
				title: "Topical paper session 6: Resource Allocation and Management for JC&S (5 papers)",
				start: "15:10",
				end: "16:40",
				chair: {
 				name: "Zhijie Cai",
 				affiliation: "CUHK‐SZ"
				},
				papers: [
					{
						"title": "Power Optimization for Integrated Sensing and Communication in Cellular Networks",
						"authors": "Jia Zhu (Beijing University of Posts and Telecommunications & BUPT, China); Xiao jun Jing, Di Zhang and Ronghui Zhang (Beijing University of Posts and Telecommunications, China)",
					},
					{
						"title": "Power Allocation Scheme for Device‐Free Localization in 6G ISAC Networks",
						"authors": "Maximiliano Rivera (RWTH Aachen University, Germany); Pradyumna Kumar Bishoyi (RWTH Aachen University Germany, Germany); Marina Petrova (RWTH Aachen University, Germany & KTH Royal Institute of Technolgy, Sweden)",
					},
					{
						"title": "Joint Communication and Sensing for 6G ‐ A Cross‐Layer Perspective",
						"authors": "Henk Wymeersch and Sharief Saleh (Chalmers University of Technology, Sweden); Ahmad Nimr (Technische Universität Dresden, Germany); Rreze Halili and Rafael Berkvens (University of Antwerp - imec, Belgium); Mohammad Hossein Moghaddam (Qamcom Research & Technology, Sweden); José Miguel Mateos-Ramos (Chalmers University of Technology, Sweden); Athanasios Stavridis (Ericsson, Sweden); Stefan Wänstedt (Ericsson Research, Sweden); Sokratis Barmpounakis (WINGS ICT Solutions, Greece); Basuki Priyanto (Sony Europe BV, Sweden); Martin Beale (Sony, United Kingdom (Great Britain)); Jaap van de Beek (Luleå University of Technology, Sweden); Zi Ye (Luleå Tekniska Universitet, Sweden); Marvin Manalastas (Nokia, USA); Apostolos Kousaridas (Nokia, Germany); Gerhard P. Fettweis (Technische Universität Dresden, Germany)",
					},
					{
						"title": "Integrated Sensing and Learning for Better Generalized Edge AI",
						"authors": "Zhijie Cai (Shenzhen Research Institute of Big Data, China); Xiaowen Cao (Shenzhen University, China); Zihan Zhang (Futian District Industry and Commerce Development Promotion Center, China); Qimei Chen (Wuhan University, China); Hang Li and Xiaoyang Li (Shenzhen Research Institute of Big Data, China); Kaifeng Han (China Academy of Information and Communications Technology, China); Yuanhao Cui (Southern University of Science and Technology, China); Guangxu Zhu (Shenzhen Research Institute of Big Data, China)",
					},
					{
						"title": "Enabling Mobility‐Oriented JCAS in 6G Networks: An Architecture Proposal",
						"authors": "Philipp Rosemann and Sanket Partani (RPTU Kaiserslautern-Landau, Germany); Marc Miranda (TU Ilmenau, Germany); Jannik Mähn (Barkhausen Institut, Germany); Michael Karrenbauer (University of Kaiserslautern-Landau, Germany); William Meli (NXP, Germany); Rodrigo Hernangómez (Fraunhofer Heinrich Hertz Institute, Germany); Maximilian Lübke (Friedrich-Alexander-Universität Erlangen-Nürnberg (FAU), Germany); Jacob Kochems (RPTU Kaiserslautern-Landau, Germany); Stefan Köpsell (TU Dresden, Germany); Anosch Aziz-Koch (NXP, Germany); Ramez Askar (Fraunhofer Institute for Telecommunications, Heinrich Hertz Institute, Germany); Julia Beuster (Technische Universität Ilmenau & Fraunhofer Institute for Integrated Circuits IIS, Germany); Oliver Blume (Robert Bosch GmbH, Germany); Norman Franchi (Friedrich-Alexander-Universität Erlangen-Nürnberg, Germany); Reiner S. Thomä (Ilmenau University of Technology, Germany); Slawomir Stanczak (Technische Universität Berlin & Fraunhofer Heinrich Hertz Institute, Germany); Hans D. Schotten (RPTU Kaiserslautern-Landau, Germany)",
					},
				],
				vimeo: "928691819",
			},
			{
				title: "Conference recap and closing",
			//	speaker: "André Bourdoux (IMEC), Prof. Gerhard Fettweis (TU Dresden), Prof. Thomas Ußmüller (Universität Innsbruck)",
				start: "16:40",
				end: "17:10",
			//	slides: [ 
			//	{
			//		name: "Conference Summary", 
			//		file: "summary_bourdoux.pdf"
			//	}, 
			//	{
			//		name: "Closing Slides", 
			//		file: "closing_slides.pdf"
			//	},
			//	],
				vimeo: "928691919",
			}

		]
	}
]
