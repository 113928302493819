
<script>

	// User-accessible Core Components
	import unlock from './unlock.vue';


	// User-defined components
	import { components } from '../../logic.js'


	var dynamic = {
		name: 'dynamic',
		components: {
			unlock: unlock,
		},
		props: {
			template: String,
		},
		data() {

			return {
				templateRender: null,
			};
		},
		render(h) {
			if (!this.templateRender) {
				return h('div', 'loading...');
			} else { // If there is a template, I'll show it
				return this.templateRender();
			}
		},
		watch: {
			// Every time the template prop changes, I recompile it to update the DOM
			template:{
				immediate: true, // makes the watcher fire on first render, too.
				handler() {
					var res = Vue.compile(this.template);

					this.templateRender = res.render;

					// staticRenderFns belong into $options,
					// appearantly
					this.$options.staticRenderFns = []

					// clean the cache of static elements
					// this is a cache with the results from the staticRenderFns
					this._staticTrees = []

					// Fill it with the new staticRenderFns
					for (var i in res.staticRenderFns) {
						this.$options.staticRenderFns.push(res.staticRenderFns[i])
					}

				}
			}
		},
		methods: {

		}

	};

	for (let k in components){
		dynamic.components[k] = components[k];
	}

	export default dynamic;

</script>
