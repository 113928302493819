export var accessibility = `
<p>
The Barkhausen Institut (owner of jcns-symposium.org) is committed to making its websites accessible, in accordance with BfWebG and BITV 2.0
(Barrierefreie-Informationstechnik-Verordnung), implementing EU Directive 2016/2102.
This accessibility statement applies to jcns-symposium.org. It does not apply to other websites we link to.
</p>

<h4>Compliance Status</h4>
<p>The website is fully compliant with BfWebG/BITV 2.0. The implemented accessibility techniques reflect the suggestions of the
Web Content Accessibility Guidelines (WCAG) 2.1 including level AAA and thereby exceed the requirements of the
harmonized European standard EN 301 549 (V2.1.2).</p>

<h4>Preparation of This Accessibility Statement</h4>
<p>This statement was prepared on October 30, 2020 following an in-depth self-assessment.</p>

<h4>Feedback and Contact Information</h4>
<p>We are committed to continously improve the accessiblity of our website and are thankful for feedback and suggestions.
If you notice any shortcomings, please contact Fabian Diehm via <strong>fabian.diehm(at)ifn.et.tu-dresden.de</strong> or call our office at +49 (351) 79991600.</p>

<h4>Enforcement Procedures</h4>
<p>If you are not satisfied with our handling of your feedback, you can contact the Saxon Arbitration Service:</p>

<p>
<strong>Representative of the Saxon State Government for the Interests of People with Disabilities</strong><br>
Albertstraße 10 <br>
01097 Dresden <br>
</p>

<p>
<strong>Postal Address:</strong> Archivstraße 1, 01097 Dresden<br>
<strong>Email:</strong> info.behindertenbeauftragter@sk.sachsen.de<br>
<strong>Telephone:</strong> 0351 564-12161
</p>

<p>
<strong>Internet:</strong> <a href="https://www.inklusion.sachsen.de">https://www.inklusion.sachsen.de</a>
</p>
	`;
