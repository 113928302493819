<template>
	<div class="sub-stream" style="margin-bottom: -4em">
		<div ref="now" class="slot fade" v-if="now">
			<h3>Now</h3>
			<div class="type" v-html="nowTypeHTML"></div>
			<div v-html="now.main"></div>
			<div v-html="nowLangHTML"></div>

		</div>
		<div ref="next" class="slot fade" v-if="next">
			<h3>Next</h3>
			<div class="when" v-html="minutesUntil"></div>
			<div class="type" v-html="nextTypeHTML"></div>
			<div v-html="next.main"></div>
			<div v-html="nextLangHTML"></div>
		</div>
	</div>
</template>

<script>
	const transitionTimeMs = 2000; // min. 1200

	export default {
		name: 'nownext',
		data: function(){
			return {
				// oldNow: null,
				// oldNext: null,
				now: null,
				next: null,
				syncHandler: null,
				fadeInEls: [],
			};
		},
		computed: {
			actualNow: function(){
				return this.g.schedule.getCurrent();
			},
			nowTypeHTML: function(){
				return this.typeHTML(this.now);
			},
			nowLangHTML: function(){
				return this.langHTML(this.now);
			},

			actualNext: function(){
				return this.g.schedule.getNext();
			},
			nextTypeHTML: function(){
				return this.typeHTML(this.next);
			},
			nextLangHTML: function(){
				return this.langHTML(this.next);
			},
			minutesUntil: function(){
				return this.g.schedule.minutesUntil(this.next);
			}

		},
		watch: {
			actualNow: function() {
				this.fadeLogic('now','actualNow');
				this.fadeLogic('next','actualNext');
			},
			// actualNext: function() {
			// }
		},

		mounted: function(){
			this.now = this.actualNow;
			this.next = this.actualNext;
			let self = this;
			setTimeout(() => {
				for (let k of ["now","next"]){
					if (self.$refs[k]) {
						self.$refs[k].classList.remove("fade");
						// self.$refs[k].classList.add("fade");
						// self.$refs[k].classList.add("in");
					}
				}

			}, 50);
			console.log("mounted");

		},
		methods: {
			fadeLogic(key,actualkey){
				console.log("updated: ", key);
				let self = this;

				function fadeIn(){
					for (let key of self.fadeInEls){
						if(self.$refs[key]){
							self.$refs[key].classList.add("fade");
							self.$refs[key].classList.add("in");
						}
					}
					self.fadeInEls = [];
					self.syncHandler = null;
				}

				function waitAndFade(key){
					self.fadeInEls.push(key);
					if (!self.syncHandler){
						self.syncHandler = setTimeout(fadeIn, transitionTimeMs);
					}
				}


				if (this[key] && (JSON.stringify(this[key]) != JSON.stringify(this[actualkey]))){
					if(self.$refs[key]){
						this.$refs[key].classList.remove("in");
						this.$refs[key].classList.add("fade");
					}
				}
				waitAndFade(key);
				setTimeout(() => {
					self[key] = self[actualkey];
				}, transitionTimeMs/2);



			},
			typeHTML: function(entry){
				if (!entry) return "";
				let res = "";
				if (entry.type == "video") res = '<i aria-hidden="true" class="fa fa-film"></i> Video';
				if (entry.type == "live") res = '<span class="live"></span>';
				if (entry.type == "live-interactive") res = '<span class="live-interactive"></span>';
				return res;
			},
			langHTML: function(entry){

				let res = "";
				if (entry){
					if (entry.lang=="de") res += '<span class="language">German</span>';
					if (entry.lang=="de/en") res += '<span class="language">German; English subtitles</span>';
				}
				return res;
			},
		}
	}

</script>

<style lang="less">


	.fade {
		-webkit-transition: opacity 0.6s ease-in !important;
		-o-transition: opacity 0.6s ease-in !important;
		transition: opacity 0.6s ease-in !important;
	}


	.sub-stream{
		margin-top: 1em;
		//padding: 0.5em;
		padding-top: 0.001em;
		font-size: 1.2em;
		margin-left: 5%;
		//background-color: #e4e4e4;
		// border-radius: 10px;
		margin-right: 5%;


		display: flex;
		flex-wrap: nowrap;
		justify-content: space-around;
		position: relative;

		@media (min-width: 800px){
			flex-direction: row;
			align-items: flex-start;
		}
		@media (max-width: 799px){
			align-items: center;
			flex-direction: column;
			.slot{
				margin-bottom: 1em;
			}
		}


		.slot{
			flex: 1 1 auto;
			padding: 0.5em;
			max-width: 28em;
			//transition: all 0.6s ease-in;
		}

		.slot.initial{
			//transition: none;
		}

		h3{
			margin-top: 0em;
			font-size: 1.6em;
			font-variant: small-caps;
		}

		.language{
			display: block;
			margin-top: 0.5em;
			color: gray;
			&::before{
				content: "\f028";
				font-family: "FontAwesome";
				margin-right: 0.5em;
			}
		}

		.aff{
			font-weight: bold;
		}
		.title{
			font-size: 1.2em;
			display: block;
			&::before, &::after{
				content: "\"";
			}
			margin-bottom: 0.4em;

		}
		.name::after{
			content: " - "
			//font-size: 0.9em;
		}
		.when{
			color: gray;
			margin-bottom: 0.5em;
			font-weight: bold;
		}
		.type{
			color: gray;
			margin-bottom: 0.5em;
			font-size: 1em;
			.live{
				font-size: 1em;
			}
		}
	}

</style>
